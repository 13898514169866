// const components = {
//   easiTable: async () => await import("../components/global/Editable"),
//   easiPlainTable: async () => await import("../components/global/Table"),
//   easiTab: async () => await import("../components/global/Tab"),
//   easiPopover: async () => await import("../components/global/Popover"),
//   easiButton: async () => await import("../components/global/Button"),
//   easiButtonNew: async () => await import("../components/global/ButtonNew"),
//   easiTextInput: async () => await import("../components/global/TextInput"),
//   easiBlinkingTextInput: async () => await import("../components/global/BlinkingTextInput"),
//   easiTextArea: async () => await import("../components/global/TextArea"),
//   easiDate: async () => await import("../components/global/DateInput"),
//   easiOtp: async () => await import("../components/global/OTPInput"),
//   easiContainer: async () => await import("../components/global/Container"),
//   easiSelectInput: async () => await import("../components/global/SelectInput"),
//   easiSelectInput2: async () => await import("../components/global/SelectInput2"),
//   easiBankSelectInput: async () => await import("../components/global/BankSelect"),
//   easiMultiSelectInput: async () => await import("../components/global/MultiSelect"),
//   easiDeduction: async () => await import("../components/global/DeductionSelect"),
//   easiModal: async () => await import("../components/global/Modal"),
//   easiSuccess: async () => await import("../components/global/SuccessModal"),
//   easiEligible: async () => await import("../components/global/EligibilityModal"),
//   easiWarn: async () => await import("../components/global/WarningModal"),
//   easiPreview: async () => await import("../components/global/PreviewModal"),
//   easiImageView: async () => await import("vue-easy-lightbox"),
//   easiLoading: async () => await import("../components/global/LoadingModal"),
//   easiCheckbox: async () => await import("../components/global/CheckBoxInput"),
//   easiBackButton: async () => await import("../components/global/BackButton"),
//   easiSearch: async () => await import("../components/Search/Search"),
//   easiEmployeeTable: async () => await import("../components/global/EmployeeTable"),
//   easiPayrollTable: async () => await import("../components/global/PayrollTable"),
//   MobilePayrollTable: async () => await import("../components/global/MobilePayrollTable"),
//   easiBonusTable: async () => await import("../components/global/BonusTable"),
//   easiPagin: async () => await import("../components/global/Pagination"),
//   easiPaginAsync: async () => await import("@/components/global/PaginationAsync.vue"),
//   easiEmployeeSelect: async () => await import("../components/global/EmployeeSelect"),
//   easiEmployeeBonus: async () => await import("../components/global/EmployeeBonusSelection"),
//   bonusSelection: async () => await import("../components/global/BonusSelection"),
//   easiProviderSelect: async () => await import("../components/global/ProviderSelect"),
//   easiLoader: async () => await import("../components/global/Loader"),
//   APIDashboardLoader: async () => await import("../components/global/APIDashboardLoader"),
//   easiRoundLoader: async () => await import("../components/global/RoundLoader"),
//   easiEmptyState: async () => await import("../components/global/EmptyState"),
//   easiFilter: async () => await import("../components/Filters/Filter"),
//   easiCard: async () => await import("@/components/global/easiCard.vue"),
//   easiAlert: async () => await import("@/components/global/CustomAlert.vue"),
//   easiLayout: async () => await import("@/components/global/AuthLayout.vue"),
//   easiUpload: async () => await import("@/components/global/UploadComponent"),
//   easiSwitch: async () => await import("@/components/global/Switch.vue"),
//   easiActiveFilter: async () => await import("@/components/Filters/ActiveFilters"),
//   easiTextCard: async () => await import("@/components/global/DisplayCard"),
//   easiCalendar: async () => await import("@/components/global/Calendar.vue"),
//   easiRoundButton: async () => await import("@/components/global/RoundeButton.vue"),
//   easiCheckBoxPlain: async () => await import("@/components/global/CheckBox.vue"),
// };

// export const registerComponents = (app) => {
//   Object.keys(components).forEach((name) => {
//     components[name]()
//       .then((component) => {
//         app.component(name, component.default || component);
//       })
//       .catch((error) => {
//         console.error(`Failed to load component ${name}:`, error);
//       });
//   });
// };

// import Button from "../components/global/Button";
// import ButtonNew from "../components/global/ButtonNew";
// import TextInput from "../components/global/TextInput";
// import BlinkingTextInput from "../components/global/BlinkingTextInput";
// import TextArea from "../components/global/TextArea";
// import OTPInput from "../components/global/OTPInput";
// import Container from "../components/global/Container";
// import SelectInput from "../components/global/SelectInput";
// import SelectInput2 from "../components/global/SelectInput2";
// import MultiSelect from "../components/global/MultiSelect";
// import DeductionSelect from "../components/global/DeductionSelect";
// import CheckBoxInput from "../components/global/CheckBoxInput";
// import BankSelect from "../components/global/BankSelect";
// import Modal from "../components/global/Modal";
// import SuccessModal from "../components/global/SuccessModal";
// import EligibilityModal from "../components/global/EligibilityModal";
// import WarningModal from "../components/global/WarningModal";
// import PreviewModal from "../components/global/PreviewModal";
// import LoadingModal from "../components/global/LoadingModal";
// import BackButton from "../components/global/BackButton";
// import Search from "../components/Search/Search";
// import EmployeeTable from "../components/global/EmployeeTable";
// import PayrollTable from "../components/global/PayrollTable";
// import MobilePayrollTable from "../components/global/MobilePayrollTable";
// import BonusTable from "../components/global/BonusTable";
// import Pagination from "../components/global/Pagination";
// import Pagination2 from "../components/global/Pagination2";
// import EmployeeSelect from "../components/global/EmployeeSelect";
// import EmployeeBonusSelection from "../components/global/EmployeeBonusSelection";
// import BonusSelection from "../components/global/BonusSelection";
// import ProviderSelect from "../components/global/ProviderSelect";
// import Editable from "../components/global/Editable";
// import Loader from "../components/global/Loader";
// import APIDashboardLoader from "../components/global/APIDashboardLoader";
// import RoundLoader from "../components/global/RoundLoader";
// import Filter from "../components/Filters/Filter";
// import EmptyState from "../components/global/EmptyState";
// import DateInput from "../components/global/DateInput";
// import VueEasyLightbox from "vue-easy-lightbox";
// import EasiCard from "@/components/global/easiCard.vue";
// import CustomAlert from "@/components/global/CustomAlert.vue";
// import NewAuthLayout from "@/components/global/AuthLayout.vue";
// import ActiveFilters from "@/components/Filters/ActiveFilters";
// import DisplayCard from "@/components/global/DisplayCard";
// import Table from "@/components/global/Table";
// import UploadComponent from "@/components/global/UploadComponent";
// import Tab from "@/components/global/Tab";
// import Popover from "@/components/global/Popover";
// import PaginationAsync from "@/components/global/PaginationAsync.vue";
// import CheckBox from "@/components/global/CheckBox.vue";
// import Calendar from "@/components/global/Calendar.vue";
// import RoundeButton from "@/components/global/RoundeButton.vue";
// import Switch from "@/components/global/Switch.vue";

import { defineAsyncComponent } from "vue";

const Button = defineAsyncComponent(() =>
  import("../components/global/Button")
);
const ButtonNew = defineAsyncComponent(() =>
  import("../components/global/ButtonNew")
);
const TextInput = defineAsyncComponent(() =>
  import("../components/global/TextInput")
);
const BlinkingTextInput = defineAsyncComponent(() =>
  import("../components/global/BlinkingTextInput")
);
const TextArea = defineAsyncComponent(() =>
  import("../components/global/TextArea")
);
const OTPInput = defineAsyncComponent(() =>
  import("../components/global/OTPInput")
);
const Container = defineAsyncComponent(() =>
  import("../components/global/Container")
);
const SelectInput = defineAsyncComponent(() =>
  import("../components/global/SelectInput")
);
const SelectInput2 = defineAsyncComponent(() =>
  import("../components/global/SelectInput2")
);
const MultiSelect = defineAsyncComponent(() =>
  import("../components/global/MultiSelect")
);
const DeductionSelect = defineAsyncComponent(() =>
  import("../components/global/DeductionSelect")
);
const CheckBoxInput = defineAsyncComponent(() =>
  import("../components/global/CheckBoxInput")
);
const BankSelect = defineAsyncComponent(() =>
  import("../components/global/BankSelect")
);
const Modal = defineAsyncComponent(() => import("../components/global/Modal"));
const SuccessModal = defineAsyncComponent(() =>
  import("../components/global/SuccessModal")
);
const EligibilityModal = defineAsyncComponent(() =>
  import("../components/global/EligibilityModal")
);
const WarningModal = defineAsyncComponent(() =>
  import("../components/global/WarningModal")
);
const PreviewModal = defineAsyncComponent(() =>
  import("../components/global/PreviewModal")
);
const LoadingModal = defineAsyncComponent(() =>
  import("../components/global/LoadingModal")
);
const BackButton = defineAsyncComponent(() =>
  import("../components/global/BackButton")
);
const Search = defineAsyncComponent(() =>
  import("../components/Search/Search")
);
const EmployeeTable = defineAsyncComponent(() =>
  import("../components/global/EmployeeTable")
);
const PayrollTable = defineAsyncComponent(() =>
  import("../components/global/PayrollTable")
);
const MobilePayrollTable = defineAsyncComponent(() =>
  import("../components/global/MobilePayrollTable")
);
const BonusTable = defineAsyncComponent(() =>
  import("../components/global/BonusTable")
);
const Pagination = defineAsyncComponent(() =>
  import("../components/global/Pagination")
);
const Pagination2 = defineAsyncComponent(() =>
  import("../components/global/Pagination2")
);
const EmployeeSelect = defineAsyncComponent(() =>
  import("../components/global/EmployeeSelect")
);
const EmployeeBonusSelection = defineAsyncComponent(() =>
  import("../components/global/EmployeeBonusSelection")
);
const BonusSelection = defineAsyncComponent(() =>
  import("../components/global/BonusSelection")
);
const ProviderSelect = defineAsyncComponent(() =>
  import("../components/global/ProviderSelect")
);
const Editable = defineAsyncComponent(() =>
  import("../components/global/Editable")
);
const Loader = defineAsyncComponent(() =>
  import("../components/global/Loader")
);
const APIDashboardLoader = defineAsyncComponent(() =>
  import("../components/global/APIDashboardLoader")
);
const RoundLoader = defineAsyncComponent(() =>
  import("../components/global/RoundLoader")
);
const Filter = defineAsyncComponent(() =>
  import("../components/Filters/Filter")
);
const EmptyState = defineAsyncComponent(() =>
  import("../components/global/EmptyState")
);
const DateInput = defineAsyncComponent(() =>
  import("../components/global/DateInput")
);
const VueEasyLightbox = defineAsyncComponent(() => import("vue-easy-lightbox"));
const EasiCard = defineAsyncComponent(() =>
  import("@/components/global/easiCard.vue")
);
const CustomAlert = defineAsyncComponent(() =>
  import("@/components/global/CustomAlert.vue")
);
const NewAuthLayout = defineAsyncComponent(() =>
  import("@/components/global/AuthLayout.vue")
);
const ActiveFilters = defineAsyncComponent(() =>
  import("@/components/Filters/ActiveFilters")
);
const DisplayCard = defineAsyncComponent(() =>
  import("@/components/global/DisplayCard")
);
const Table = defineAsyncComponent(() => import("@/components/global/Table"));
const UploadComponent = defineAsyncComponent(() =>
  import("@/components/global/UploadComponent")
);
const Tab = defineAsyncComponent(() => import("@/components/global/Tab"));
const Popover = defineAsyncComponent(() =>
  import("@/components/global/Popover")
);
const PaginationAsync = defineAsyncComponent(() =>
  import("@/components/global/PaginationAsync.vue")
);
const CheckBox = defineAsyncComponent(() =>
  import("@/components/global/CheckBox.vue")
);
const Calendar = defineAsyncComponent(() =>
  import("@/components/global/Calendar.vue")
);
const RoundeButton = defineAsyncComponent(() =>
  import("@/components/global/RoundeButton.vue")
);
const Switch = defineAsyncComponent(() =>
  import("@/components/global/Switch.vue")
);

export const registerComponents = (app) => {
  app
    .component("easiTable", Editable)
    .component("easiPlainTable", Table)
    .component("easiTab", Tab)
    .component("easiPopover", Popover)
    .component("easiButton", Button)
    .component("easiButtonNew", ButtonNew)
    .component("easiTextInput", TextInput)
    .component("easiBlinkingTextInput", BlinkingTextInput)
    .component("easiTextArea", TextArea)
    .component("easiDate", DateInput)
    .component("easiOtp", OTPInput)
    .component("easiContainer", Container)
    .component("easiSelectInput", SelectInput)
    .component("easiSelectInput2", SelectInput2)
    .component("easiBankSelectInput", BankSelect)
    .component("easiMultiSelectInput", MultiSelect)
    .component("easiDeduction", DeductionSelect)
    .component("easiModal", Modal)
    .component("easiSuccess", SuccessModal)
    .component("easiEligible", EligibilityModal)
    .component("easiWarn", WarningModal)
    .component("easiPreview", PreviewModal)
    .component("easiImageView", VueEasyLightbox)
    .component("easiLoading", LoadingModal)
    .component("easiCheckbox", CheckBoxInput)
    .component("easiBackButton", BackButton)
    .component("easiSearch", Search)
    .component("easiEmployeeTable", EmployeeTable)
    .component("easiPayrollTable", PayrollTable)
    .component("MobilePayrollTable", MobilePayrollTable)
    .component("easiBonusTable", BonusTable)
    .component("easiPagin", Pagination)
    .component("easiPaginAsync", PaginationAsync)
    .component("easiEmployeeSelect", EmployeeSelect)
    .component("easiEmployeeBonus", EmployeeBonusSelection)
    .component("bonusSelection", BonusSelection)
    .component("easiProviderSelect", ProviderSelect)
    .component("easiLoader", Loader)
    .component("APIDashboardLoader", APIDashboardLoader)
    .component("easiRoundLoader", RoundLoader)
    .component("easiEmptyState", EmptyState)
    .component("easiFilter", Filter)
    .component("easiCard", EasiCard)
    .component("easiAlert", CustomAlert)
    .component("easiLayout", NewAuthLayout)
    .component("easiUpload", UploadComponent)
    .component("easiSwitch", Switch)
    .component("easiActiveFilter", ActiveFilters)
    .component("easiTextCard", DisplayCard)
    .component("easiCalendar", Calendar)
    .component("easiRoundButton", RoundeButton)
    .component("easiCheckBoxPlain", CheckBox);
};
