<template>
  <main class="flex md:flex-row flex-col w-full items-start gap-0">
    <div class="flex flex-col">
      <div
        class="flex md:flex-col md:mt-2 flex-row items-center mb-5 md:mb-0 md:items-start md:justify-start gap-2 md:gap-5 w-full"
      >
        <div
          @click="bankTransfer = true"
          :class="[
            bankTransfer
              ? 'shadow shadow-xl bg-white border  border-outlineGray'
              : 'bg-none border-none border-gray',
            payroll
              ? 'w-36 md:w-52 md:h-auto h-10'
              : 'w-36 md:w-64 md:h-auto h-10',
          ]"
          class="cursor-pointer flex flex-col items-center md:justify-center gap-1 relative md:pt-2 md:px-4 md:pb-5 rounded md:rounded-r rounded-xl md:rounded-2xl"
        >
          <span
            :class="
              !bankTransfer
                ? 'font-bold text-primary-deep'
                : 'font-bold text-secondary'
            "
            class="text-sm md:text-base font-bold mt-2"
            >Bank Transfer</span
          >
          <!-- <img v-show="bankTransfer" src="@/assets/icons/arrow.svg?url" alt="" /> -->
          <!-- <img
            v-show="!bankTransfer"
            src="@/assets/icons/arrow-inactive.svg?url"
            alt=""
          /> -->
        </div>

        <div
          @click="bankTransfer = false"
          :class="[
            !bankTransfer
              ? 'shadow shadow-xl bg-white border  border-outlineGray'
              : 'bg-none border-none border-gray',
            payroll
              ? 'w-36 md:w-52 md:h-auto h-10'
              : 'w-36 md:w-64 md:h-auto h-10',
          ]"
          class="cursor-pointer flex flex-col items-center md:justify-center gap-1 relative md:pt-2 md:px-4 md:pb-5 rounded md:rounded-r rounded-xl md:rounded-2xl"
        >
          <span
            :class="
              bankTransfer
                ? 'font-bold text-primary-deep'
                : 'font-bold text-secondary'
            "
            class="text-sm md:text-base font-bold mt-2"
            >Paystack</span
          >
          <!-- <img
            v-show="!bankTransfer"
            class="invisible"
            src="@/assets/icons/arrow.svg?url"
            alt=""
          />
          <img
            v-show="bankTransfer"
            class="invisible"
            src="@/assets/icons/arrow-inactive.svg?url"
            alt=""
          /> -->
        </div>
      </div>
    </div>

    <div
      v-if="bankTransfer"
      class="bg-white w-full md:w-7/12 rounded shadow-md md:shadow-lg rounded-2xl border border-boxBorder py-4 px-2 md:p-6"
    >
      <div
        v-if="currentTier > 0"
        class="flex flex-col items-start px-5 md:px-5 mt-2 md:mt-2 mb-7 gap-5"
      >
        <!-- <img
          class="w-52 md:w-64"
          src="@/assets/icons/gradient-text.svg?url"
          alt=""
        /> -->
        <h2 class="font-bold text-xl text-primary-deep">
          Pay Via Bank Transfer
        </h2>
      </div>

      <div
        v-if="currentTier == 0"
        class="flex flex-col w-full px-4 md:px-5 gap-7 items-center"
      >
        <img class="w-20" :src="ineligibleIcon" alt="" />

        <span class="text-dark-800 text-lg"
          >Kindly complete KYC Tier 1 to get your virtual account details.</span
        >
        <div class="w-full flex flex-col gap-y-8">
          <easiButton
            @click="$router.push({ name: 'Profile' })"
            :block="true"
            color="primary"
            type="submit"
            class="md:min-w-full md:max-w-full md:w-auto w-full focus:outline-none text-sm py-3 bg-primary rounded-lg text-white"
          >
            Continue
          </easiButton>
        </div>
      </div>

      <div
        v-else-if="currentTier > 0"
        class="flex flex-col items-start px-4 md:px-10 gap-5"
      >
        <div class="flex flex-col items-start">
          <span class="text-dark-200 text-xs">Bank Name:</span>
          <span class="text-dark-800 font-medium text-sm">{{
            companyAccount.bankName
          }}</span>
        </div>

        <div class="flex flex-col items-start">
          <span class="text-dark-200 text-xs">Account Name:</span>
          <span class="text-dark-800 font-medium text-sm">{{
            companyAccount.accountName
          }}</span>
        </div>
        <div class="flex gap-x-6">
          <div class="flex flex-col items-start">
            <span class="text-dark-200 text-xs">Account Number:</span>

            <span id="copy" class="text-dark-800 font-medium text-sm">{{
              companyAccount.accountNumber
            }}</span>
          </div>
          <div
            @click="copyText(companyAccount.accountNumber)"
            class="bg-white rounded rounded-xl px-4 py-2 cursor-pointer flex items-center gap-3"
          >
            <img src="@/assets/icons/copy.svg?url" alt="" />
            <span class="text-dark-800 font-bold inline-block text-sm"
              >Copy</span
            >
          </div>
        </div>
      </div>
      <div
        v-if="currentTier > 0"
        class="flex flex-col px-6 md:px-0 md:flex-row gap-6 w-full my-6 md:justify-center md:items-center"
      >
        <span
          @click="transferNotification = true"
          class="w-full md:w-10/12 mt-4 cursor-pointer text-center bg-primary rounded-full text-white font-medium border-none border-secondary px-3 text-sm py-3"
          >I Have Made The Transfer</span
        >
      </div>
    </div>

    <div
      v-else
      class="bg-white w-full md:w-7/12 rounded shadow-md md:shadow-lg rounded-2xl border border-boxBorder md:p-2"
    >
      <div
        v-show="!linkGenerated && currentTier > 0"
        class="flex flex-col items-center"
      >
        <div
          class="bg-white w-full flex justify-center p-5 md:border-b border-dividerColor mb-4"
        >
          <img class="w-64" src="@/assets/icons/paystack.svg?url" alt="" />
        </div>

        <form class="flex gap-4" action="">
          <div>
            <label class="text-dark-800 font-normal text-sm mr-2" for="amount"
              >One Time Payment</label
            >
            <input
              type="radio"
              name="percentage"
              id="percentage"
              :value="false"
              v-model="recurrentPayment"
              :checked="!recurrentPayment"
            />
          </div>

          <div>
            <label
              class="text-dark-800 font-normal text-sm mx-2"
              for="percentage"
              >Recurrent Payment</label
            >
            <input
              type="radio"
              name="percentage"
              id="percentage"
              :value="true"
              v-model="recurrentPayment"
              :checked="recurrentPayment"
            />
          </div>
        </form>
      </div>

      <hr
        v-show="currentTier > 0"
        class="mt-5 mb-0 border border-outlineGray text-outlineGray"
      />

      <div
        v-if="currentTier == 0"
        class="flex flex-col w-full py-4 px-4 md:px-5 gap-7 items-center"
      >
        <img class="w-20" :src="ineligibleIcon" alt="" />

        <span class="text-dark-800 text-lg"
          >Kindly complete KYC Tier 1 to fund your wallet.</span
        >
        <div class="w-full flex flex-col gap-y-8">
          <easiButton
            @click="$router.push({ name: 'Profile' })"
            :block="true"
            color="primary"
            type="submit"
            class="md:min-w-full md:max-w-full md:w-auto w-full focus:outline-none text-sm py-3 bg-primary rounded-lg text-white"
          >
            Continue
          </easiButton>
        </div>
      </div>

      <form
        @submit.prevent="initPaystack"
        v-else-if="!linkGenerated && currentTier > 0"
        class="flex flex-col w-full mt-2 mb-6"
        autocomplete="on"
      >
        <div class="flex flex-col gap-4 px-3 md:px-6 lg:px-4 xl:px-5">
          <!-- <label
            for="paymentName"
            class="text-left text-dark-800 text-sm font-medium"
            >Payment Name</label
          > -->
          <easiTextInput
            placeholder="Payment Name"
            type="text"
            name="paymentName"
            class="mt-1 mb-5"
            v-model="payment.name"
          />

          <div>
            <!-- <label
              for="description"
              class="text-dark-800 text-left text-sm font-medium"
              >Description
            </label> -->
            <easiTextArea
              class="focus:border-primary focus:ring-primary border-dark-300 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border rounded-sm py-3 px-4 block appearance-none rounded-lg mt-1 mb-0"
              name="description"
              id="description"
              cols="30"
              rows="5"
              placeholder="Payment Description"
              v-model="payment.desc"
            ></easiTextArea>
          </div>

          <!-- <label
            for="amount"
            class="text-left text-dark-800 text-sm font-medium"
            >Amount</label
          > -->
          <easiTextInput
            placeholder="Input Amount"
            type="text"
            :format="true"
            name="amount"
            v-model="args.amount"
            class="mt-1 mb-5"
            required
          />
          <div>
            <label
              v-show="recurrentPayment"
              for="Interval"
              class="text-dark-800 text-left text-sm font-medium"
              >Select Interval</label
            >

            <easiSelectInput
              v-if="recurrentPayment === true"
              required
              class="mt-1 mb-5 text-left"
              @update="interval = $event"
              :value="interval"
              :options="payrollInterval"
              :searchable="true"
              :autoHeight="true"
            />
          </div>
        </div>
        <div
          class="w-11/12 self-center my-3 md:justify-center md:items-center px-4 md:px-0"
        >
          <easiButton
            :block="true"
            color="primary"
            class="md:min-w-full md:max-w-full md:w-auto focus:outline-none text-sm py-3 bg-primary rounded-lg text-white"
          >
            Generate Payment Link
          </easiButton>
        </div>
      </form>
    </div>

    <easiModal v-if="payStack" @close="payStack = false">
      <template v-slot:header>
        <div class="flex flex-col items-center justify-center6 gap-2">
          <h1 class="capitalize font-medium text-headerText">pay via</h1>

          <div class="bg-white w-11/12 rounded rounded-xl p-5">
            <img src="@/assets/icons/paystack.svg?url" alt="" />
          </div>
        </div>
      </template>
      <div class="flex flex-col items-center">
        <span
          @click="copyText(link)"
          class="text-hoverColor px-10 font-medium text-sm text-center"
          >{{ link }}</span
        >

        <div class="flex mt-2 gap-x-5 justify-center items-center">
          <div
            @click="copyText(link)"
            class="bg-white rounded rounded-xl px-4 py-2 cursor-pointer flex items-center gap-3"
          >
            <img src="@/assets/icons/copy.svg?url" alt="" />
            <span class="text-dark-800 font-bold inline-block text-sm"
              >Copy</span
            >
          </div>
          <div
            @click="
              () => {
                shareLink = true;
              }
            "
            class="bg-white rounded rounded-xl px-4 py-2 cursor-pointer flex items-center gap-3"
          >
            <img src="@/assets/icons/share.svg?url" alt="" />
            <span class="text-dark-800 font-bold inline-block text-sm"
              >Share</span
            >
          </div>
        </div>
      </div>

      <hr class="mt-5 mb-7 border-0.5 border-dividerColor text-dividerColor" />
      <div
        class="flex flex-col md:flex-row px-6 md:px-0 gap-6 w-full my-6 md:justify-center flex-col-reverse md:items-center"
      >
        <span
          @click="
            () => {
              payStack = false;
              linkGenerated = false;
            }
          "
          class="w-full md:w-40 cursor-pointer text-center bg-white rounded-full text-dark-800 font-medium border border-primary px-3 text-sm py-3"
          >Cancel</span
        >
        <easiButton
          color="primary"
          @click="
            () => {
              payStack = false;
              linkGenerated = false;
              transferNotification = true;
            }
          "
          class="w-full md:w-auto focus:outline-none text-sm py-3 px-4 bg-primary rounded-full text-white"
        >
          <span class="text-sm"> I Have Made The Transfer</span>
        </easiButton>
      </div>
    </easiModal>

    <easiModal v-if="shareLink" @close="shareLink = false">
      <template v-slot:header>
        <div class="flex items-center gap-x-4 justify-center">
          <img src="@/assets/icons/share-black.svg?url" alt="" />
          <h1 class="capitalize text-3xl font-medium text-headerText">Share</h1>
        </div>
      </template>

      <form @submit.prevent="sendEmail" class="flex flex-col" autocomplete="on">
        <div class="flex flex-col px-3 md:px-12 lg:px-8 xl:px-10">
          <easiTextInput
            v-model="emailAddress"
            placeholder="Input Email Address"
            type="email"
            name="email"
            class="mt-1 mb-5"
            required
          />
        </div>
        <hr
          class="mt-6 md:mb-8 mb-4 border-0.5 border-dividerColor text-dividerColor"
        />
        <div class="px-10 w-full">
          <easiButtonNew
            color="primary"
            :loading="loading"
            :block="true"
            class="w-full mb-4 md:mb-5"
            >Send Mail</easiButtonNew
          >
        </div>
      </form>
    </easiModal>
    <easiModal
      :isBorder="false"
      v-if="transferNotification"
      @close="transferNotification = false"
    >
      <div class="flex flex-col items-center justify-center px-4 w-full gap-8">
        <span class="text-base text-center mt-9 font-medium text-dark-800"
          >Wallet will be updated as soon as the <br />
          money is received.</span
        >
        <img src="@/assets/icons/wallet-icon.svg?url" alt="" />
      </div>

      <hr class="mt-8 mb-4 border-0.5 border-dividerColor text-dividerColor" />
      <div class="flex gap-x-6 w-full my-6 justify-center items-center">
        <easiButton
          color="primary"
          @click="bactToWallet"
          class="w-80 md:w-40 focus:outline-none text-sm py-3 px-4 bg-primary rounded-lg text-white"
        >
          <span class="text-sm"> Back to Wallet</span>
        </easiButton>
      </div>
    </easiModal>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Payment successful</span>
      </template>
    </easiSuccess>

    <easiRoundLoader v-if="loading" />

    <easiSuccess v-if="shareSuccess" @close="shareSuccess = false">
      <template v-slot:message>
        <span>{{ "Link shared successfully" }}</span>
      </template>
    </easiSuccess>
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";
import ineligibleIcon from "@/assets/icons/empty-state.svg?url";

const toast = useToast();
const store = useDataStore();

const { formatAmount, calculatePercentage } = helperFunctions;

const recurrentPayment = ref(false);
const companyAccount = computed(() => store.getCompanyAccountDetails);
const payStackRes = computed(() => store.getPayStackResponse);
const companyDetails = computed(() => store.getCompanyDetails);
const currentTier = computed(() => {
  if (companyDetails.value && companyDetails.value.tier) {
    return Number(companyDetails.value.tier);
  }
  return 0;
});
// companyDetails.registrationType !== 'COMPANY'"
const emit = defineEmits(["done"]);
const hasBvn = ref(false);
const bvnNumber = ref("");
const { query, mutate } = store;
const props = defineProps({
  adminEmail: {
    type: String,
    default: "",
  },
  payroll: {
    type: Boolean,
    default: false,
  },
});

const args = ref({
  amount: 0,
  email: "",
});
const interval = ref("");
const bankTransfer = ref(true);
const accountNumber = ref("0711456765");
const link = ref("");
const loading = ref(false);
const topupOption = ref(false);
const payStack = ref(false);
const updateSuccess = ref(false);
const shareSuccess = ref(false);
const linkGenerated = ref(false);
const shareLink = ref(false);
const transferDetails = ref(false);
const transferNotification = ref(false);

const emailAddress = ref("");
const payment = ref({ name: "", desc: "" });
const mailArgs = ref({
  HTMLPart: null,
  To: [
    {
      Email: "",
    },
  ],
  Subject: "",
});

function copyText(val) {
  var text = val;
  navigator.clipboard.writeText(text);
  toast.info("Copied!");
}
const payrollInterval = ref(["Weekly", "Monthly"]);
function generatePaymentLink() {
  payStack.value = true;
}

async function queryCompany() {
  try {
    await query({
      endpoint: "FetchCompaniesByAdminId",
      service: "SETTINGS",
      storeKey: "companyDetails",
    });
  } catch (e) {
    toast.error(e.message);
    console.log(e);
  }
}
onMounted(async () => {
  await queryCompany();
});

async function sendEmail() {
  const paymentLink = link.value;
  const email = emailAddress.value;
  loading.value = true;

  try {
    let res = await mutate({
      endpoint: "SendPaymentLink",
      data: { input: { paymentLink, email } },
      service: "PAYROLL",
    });
    if (res.success) {
      loading.value = false;
      shareLink.value = false;
      shareSuccess.value = true;

      setTimeout(() => {
        shareSuccess.value = false;
      }, 1000);
    } else {
      toast.error(res.message);
      loading.value = false;
    }
  } catch (err) {
    loading.value = false;
    console.log(err);
  } finally {
    loading.value = false;
  }
}

async function queryCompanyAccountDetails() {
  try {
    await query({
      endpoint: "ViewCompanyVirtualAccount",
      service: "AUTH",
      storeKey: "companyAccountDetails",
    });
  } catch (e) {
    console.log(e);
  }
}

const initPaystack = async () => {
  args.value.amount = Number(args.value.amount);
  loading.value = true;

  try {
    let res = await mutate({
      endpoint: "PaystackPaymentInit",
      data: { input: args.value },
      service: "PAYROLL",
    });

    if (res.authorizationUrl.length) {
      link.value = res.authorizationUrl;
      await verifyPaystack(res.reference);
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  } finally {
    loading.value = false;
  }
};

async function verifyPaystack(paymentReference) {
  try {
    await query({
      endpoint: "VerifyPaystackPayment",
      service: "PAYROLL",
      payload: { input: { paymentReference } },
      storeKey: "payStackResponse",
    });
    if (payStackRes.value.paymentSuccessful) {
      args.value.amount = 0;
      bankTransfer.value = true;
      payStack.value = true;
      loading.value = false;
    }
  } catch (e) {
    console.log(e);
  }
}

async function addBvn(endpoint) {
  loading.value = true;

  try {
    let res = await mutate({
      endpoint,
      data: { input: { bvn: bvnNumber.value } },
      service: "SETTINGS",
    });
    if (res.success) {
      await queryCompanyAccountDetails();
      await queryCompany();
    } else {
      toast.error(res.message);
    }
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
}

async function submitBvn() {
  try {
    if (bvnNumber.value.length !== 11) {
      toast.error("BVN length must be 11 characters");
      return false;
    }
    if (companyDetails.value) {
      if (companyDetails.value.registrationType === "COMPANY") {
        await addBvn("CreateAccountInformation");
        return;
      }
      await addBvn("CreateAccountInformation");
    }
  } catch (e) {
    console.log(e);
  }
}

onMounted(async () => {
  args.value.email = props.adminEmail;
  await queryCompanyAccountDetails();
});

function bactToWallet() {
  transferNotification.value = false;
  emit("done", true);
}
</script>

<style scoped>
.wallet {
  background: linear-gradient(89.6deg, #0a244f 0.35%, #c92f02 268.44%);
}
</style>
