<template>
  <div class="w-full flex flex-col gap-4">
    <div class="w-full flex items-center justify-between">
      <h4 class="text-lg md:text-xl font-medium">KYC Verification</h4>

      <div
        :style="`background: conic-gradient(#EA4E4B ${
          percentage * 3.6
        }deg, #f2f1f1 0deg)`"
        class="relative w-10 h-10 p-1 rounded-full"
      >
        <div
          class="bg-white w-full h-full flex flex-col items-center justify-center rounded-full"
        >
          <span class="font-bold text-dark-800">{{
            activeOption + 1 + "/" + data.length
          }}</span>
        </div>
      </div>
    </div>
    <hr class="border-1 border-dark-100" />

    <Stepper
      @changeActive="setActive($event)"
      :optionData="data"
      :activeNum="activeOption"
    />

    <keep-alive>
      <component :is="display" class="pb-2 md:pb-8">
        <div class="flex items-center gap-2 md:gap-4">
          <div
            v-for="(btn, i) in stepperBtn"
            :key="i"
            @click="btn.action"
            :class="
              btn.disabled ? 'opacity-40 pointer-events-none' : 'cursor-pointer'
            "
            class="h-8 md:h-10 w-8 md:w-10 hover:opacity-80 flex items-center text-primary justify-center rounded-full border border-primary"
          >
            <i :class="[btn.icon, 'text-sm md:text-base']"></i>
          </div>
        </div>
      </component>
    </keep-alive>

    <easiRoundLoader v-if="loading" />
  </div>
</template>

<script setup>
import { useDataStore } from "@/stores/data.js";
import { computed, onMounted, ref, shallowRef } from "vue";

import PersonalInfo from "./PersonalInfo/Index.vue";
import AccountInfo from "./AccountInfo/Index.vue";
import Document from "./Document/Index.vue";
import Video from "./Video/Index.vue";

import personalIcon from "@/assets/icons/account.svg?url";
import documentIcon from "@/assets/icons/document.svg?url";
import videoIdon from "@/assets/icons/video.svg?url";

import Stepper from "@/components/global/MobileStepper.vue";

const store = useDataStore();
const { query } = store;

const activeOption = ref(0);
const percentage = computed(() => {
  return ((activeOption.value + 1) / data.value.length) * 100;
});

const display = computed(() => {
  const arr = [PersonalInfo, AccountInfo, Document, Video];
  return arr[activeOption.value];
});
const loading = ref(false);

const data = ref([
  {
    title: "Personal Information",
    complete: false,
    active: true,
    value: "personal",
    icon: personalIcon,
  },
  {
    title: "Account Information",
    complete: false,
    active: false,
    value: "account",
  },
  {
    title: "Valid Identity Document",
    complete: false,
    active: false,
    value: "document",
    icon: documentIcon,
  },
  {
    title: "Video Verification",
    complete: false,
    active: false,
    value: "video",
    icon: videoIdon,
  },
]);

const stepperBtn = computed(() => {
  return [
    {
      icon: "pi pi-arrow-left",
      disabled: activeOption.value == 0,
      action() {
        if (activeOption.value > 0) {
          activeOption.value--;
        }
      },
    },
    {
      icon: "pi pi-arrow-right",
      disabled: activeOption.value == data.value.length - 1,
      action() {
        if (activeOption.value < data.value.length - 1) {
          activeOption.value++;
        }
      },
    },
  ];
});

function setActive(e) {
  data.value[e].active = true;
  activeOption.value = e;
  console.log(e, "index");
}

const queryKyc = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "GetKycInformation",
      payload: {},
      service: "SETTINGS",
      storeKey: "listKyc",
    });
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  await queryKyc();
});
</script>

<style></style>
