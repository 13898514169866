<template>
  <div class="flex items-center gap-2 flex-wrap mt-2">
    <button
      v-for="(item, i) in filters"
      :key="`filters-${i}`"
      @click="$emit('close', item)"
      class="flex items-center gap-2 text-xs text-primary font-medium focus:outline-none border border-outlineGray hover:opacity-70 rounded-full p-2"
    >
      <span class="capitalize"> {{ item }} </span>
      <img src="@/assets/icons/x-mark.svg?url" alt="" />
    </button>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
const props = defineProps({
  filters: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(["close"]);
const visibleData = ref([]);
const watchFilters = computed(() => props.filters);

visibleData.value = props.filters;

watch(watchFilters, () => {
  visibleData.value = props.filters;
});
</script>

<style></style>
