<template>
  <div>
    <form
      @submit.prevent="submit"
      class="rounded-2xl md:rounded-3xl border border-dark-100 p-2 md:p-4 flex flex-col gap-4 text-xs md:text-sm"
    >
      <p class="text-xs md:text-sm">
        Kindly provide the following information of account verification: Bank
        Verification Number, your bank account details and 6 month bank
        statement.
      </p>

      <div class="flex flex-col">
        <span class="text-sm md:text-base font-semibold -mb-3">1.</span>
        <easiTextInput
          placeholder="Bank Verification Number"
          v-model="args.bvn"
          required
        ></easiTextInput>
      </div>
      <div class="flex flex-col gap-2">
        <span class="text-sm md:text-base font-semibold md:-mb-4">2.</span>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
          <easiBankSelectInput
            @update="updateBank($event)"
            :value="args.bankName"
            placeholder="Select Bank"
            class="-mb-3"
            :autoHeight="true"
          >
          </easiBankSelectInput>
          <!-- <easiSelectInput2
          :options="bankOptions"
          :value="args.bankName"
          @update="updateBank($event)"
          placeholder="Select Bank"
          class="-mb-3"
        ></easiSelectInput2> -->
          <easiTextInput
            placeholder="Account Number"
            v-model="args.accountNumber"
            type="number"
            required
          ></easiTextInput>
        </div>
        <div class="bg-newGrey rounded-lg md:rounded-xl p-2 px-4 text-lg mt-2">
          {{ args.accountName }}
        </div>
      </div>

      <div class="flex flex-col gap-2">
        <span class="text-sm md:text-base font-semibold">3.</span>
        <div
          class="py-2 px-4 border border-dark-100 rounded-xl bg-newGrey flex items-center justify-between"
        >
          <div class="flex items-center gap-2">
            <div
              class="h-12 w-12 bg-white rounded-full flex items-center justify-center flex-shrink-0"
            >
              <img src="@/assets/icons/pdf-icon.svg?url" alt="" />
            </div>
            <div class="flex flex-col leading-4">
              <span class="mb-2">
                Upload 6 months bank statement (PDF) 6 month bank
                statement</span
              >

              <div
                v-if="uploadFile && uploadFile.name"
                class="text-center font-bold text-sm flex-col leading-4"
              >
                {{ uploadFile.name }}
              </div>

              <div
                v-else-if="args.sixMonthsBankStatement"
                class="text-center font-bold text-sm flex-col leading-4"
              >
                {{ getLink(args.sixMonthsBankStatement) }}
              </div>
            </div>
          </div>

          <span class="bg-white rounded-full">
            <easiUpload
              :label="'Upload'"
              :loop="true"
              @fileUrl="uploadFile = $event"
              :imageLink="
                kycInfo && kycInfo.sixMonthsBankStatement
                  ? kycInfo.sixMonthsBankStatement
                  : ''
              "
            />
          </span>
        </div>
      </div>

      <div class="text-xs md:text-sm">
        <span class="text-error font-bold leading-6">Important Notice</span>
        <p>
          Please ensure you submit the right bank account as you can only do
          this once.
        </p>
      </div>

      <div
        class="flex w-full flex-col-reverse md:flex-row md:items-center gap-4 justify-between"
      >
        <easiButton
          type="button"
          @click="() => $emit('saved')"
          :block="breakPoints('md')"
          variant="text"
          color="secondary"
          size="medium"
          >{{ breakPoints("md") ? "Discard Changes" : "Cancel" }}</easiButton
        >

        <div class="flex flex-col md:flex-row md:items-center md:gap-4">
          <easiButton
            type="button"
            variant="outlined"
            size="medium"
            class="hidden md:flex"
            >Save Update</easiButton
          >
          <easiButton type="submit" :block="breakPoints('md')" size="medium"
            >Submit</easiButton
          >
        </div>
      </div>
    </form>
    <easiRoundLoader v-if="loading" />
  </div>
</template>

<script setup>
import banks from "@/utils/banks";

import { computed, reactive, ref, watch } from "vue";
import { useToast } from "vue-toastification";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";

const { uploadFileToServer } = helperFunctions;
const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();
const emit = defineEmits(["saved"]);

const { breakPoints } = helperFunctions;

const toUpdate = ref(false);
const kycInfo = computed(
  () =>
    store.getKyc && store.getKyc.data && store.getKyc.data.accountInformation
);

const bankOptions = computed(() => {
  return banks.map((el) => {
    return {
      label: el.name,
      value: el.name,
    };
  });
});
const args = reactive({
  sixMonthsBankStatement: "",
  bvn: "",
  bankName: "",
  bankCode: "",
  accountNumber: "",
  accountName: "",
});
const loading = ref(false);

const uploadFile = ref(null);

function updateBank(e) {
  args.bankName = e;
  const data = banks.find((b) => b.name === e);
  args.bankCode = data.code;
}
async function verifyAccountName() {
  let endpointData = {
    accountNumber: String(args.accountNumber),
    bankCode: args.bankCode,
  };
  loading.value = true;
  try {
    let res = await mutate({
      endpoint: "VerifyAccountDetails",
      data: { input: endpointData },
      service: "EMP",
    });
    if (res.success === true) {
      args.accountName = res.data.data.account_name;
      toast.success("Account name verified.");
    }
  } catch (e) {
    toast.error(e.message);
  } finally {
    loading.value = false;
  }
}

const errorRules = reactive({
  bvn: false,
  bank: false,
  accountNumber: false,
  accountName: false,
  bankStatement: false,
});
const accNum = computed(() => String(args.accountNumber));
watch(accNum, (newval, oldval) => {
  if (args.accountNumber && args.accountNumber.length === 10) {
    // await verifyAccountName();
    verifyAccountName();
  }
});

function validate() {
  if (uploadFile.value || toUpdate.value) {
    return true;
  }
  toast.error("Upload all files");
  return false;
}
const createAccountInformation = async () => {
  loading.value = true;
  if (uploadFile.value) {
    args.sixMonthsBankStatement = await uploadFileToServer(uploadFile.value);
  }

  try {
    let res = await mutate({
      endpoint: "CreateAccountInformation",
      data: { input: args },
      service: "SETTINGS",
    });
    if (res && res.success) {
      toast.success(res.message);
      emit("saved");
    } else if (res && !res.success) {
      toast.error(res.message);
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
};

if (kycInfo.value && kycInfo.value.accountNumber) {
  toUpdate.value = true;
  args.accountName = kycInfo.value.accountName;
  args.accountNumber = kycInfo.value.accountNumber;
  args.bankCode = kycInfo.value.bankCode;
  args.bankName = kycInfo.value.bankName;
  args.sixMonthsBankStatement = kycInfo.value.sixMonthsBankStatement;
  args.bvn = kycInfo.value.bvn;
}

async function submit() {
  if (validate()) {
    await createAccountInformation();
  }
}

function getLink(url) {
  if (url) {
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    return filename;
  }
}
</script>

<style></style>
