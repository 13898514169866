<template>
  <div>
    <p class="">{{ label }}</p>
    <div class="flex gap-5 flex-wrap mt-2">
      <div
        class="border border-outlineGray rounded-lg flex gap-2 py-1.5 px-2 cursor-pointer"
        :class="toggleAll ? 'bg-gray-100' : ''"
        @click="handleToggleAll"
      >
        <img v-if="toggleAll" src="@/assets/icons/check.svg?url" alt="" />
        <div class="text-sm">Select All</div>
      </div>
      <div
        v-for="(state, stateIndex) in list"
        :key="stateIndex"
        class="border border-outlineGray rounded-lg flex gap-2 py-1.5 px-2 cursor-pointer"
        :class="selectedStates.includes(state) ? 'bg-gray-100' : ''"
        :style="{ height: 'fit-content' }"
        @click="selectState(state)"
      >
        <img
          v-if="selectedStates.includes(state)"
          src="@/assets/icons/check.svg?url"
          alt=""
        />
        <div class="text-sm">{{ state }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, watch } from "vue";
export default {
  name: "Select component",
  emits: ["change"],
  props: {
    list: {
      type: Array,
      default: [],
    },
    selectedProps: {
      type: Array,
      default: [],
    },
    label: {
      type: String,
      default: "",
    },
    reset: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const instance = getCurrentInstance();

    const toggleAll = ref(false);
    const selectedStates = ref(props.selectedProps);
    const services = ref(props.list);

    const selectState = (state) => {
      if (selectedStates.value.includes(state)) {
        // remove the state
        selectedStates.value = selectedStates.value.filter(
          (item) => item !== state
        );
        // tiles.value = selectedStates.value.filter((item) => item !== state);
      } else {
        selectedStates.value.push(state);
      }
    };
    const handleToggleAll = () => {
      toggleAll.value = !toggleAll.value;
      if (!toggleAll.value) {
        selectedStates.value = [];
      }
    };
    watch(
      () => props.reset,
      () => {
        selectedStates.value = [];
      }
    );

    watch(
      selectedStates,
      (newValue) => {
        if (props.list.length === newValue.length) {
          toggleAll.value = true;
        } else {
          toggleAll.value = false;
        }

        instance.emit("change", newValue);
      },
      {
        deep: true,
        immediate: true,
      }
    );
    watch(toggleAll, (newValue, oldValue) => {
      if (newValue) {
        services.value.forEach((state) => {
          if (!selectedStates.value.includes(state)) {
            selectedStates.value.push(state);
          }
        });
      } else {
        // selectedStates.value = [];
      }
    });
    return {
      toggleAll,
      selectedStates,
      selectState,
      handleToggleAll,
    };
  },
};
</script>

<style lang="scss" scoped></style>
