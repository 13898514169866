<template>
  <div class="flex flex-col gap-6">
    <div
      class="flex items-center justify-between gap-4 uppercase md:capitalize border border-dark-100 rounded-md p-2 md:p-0 md:rounded-none md:border-none"
    >
      <div class="flex items-center gap-2 md:gap-4">
        <h2 class="md:text-lg">Video Verification</h2>
        <GetStatus v-if="kycInfo && kycInfo.status" :status="kycInfo.status" />
      </div>
      <div>
        <div>
          <slot />
        </div>
      </div>
    </div>

    <div
      class="border border-dark-100 rounded-3xl p-4 flex flex-col gap-4 md:gap-6 text-xs md:text-sm"
    >
      <div>
        Record a 10 seconds video of yourself saying your name, why you’re
        opening an Eazipay account and where you live.. e.g
        <span class="text-blue-500">
          “ hello my name is ….. , i am opening an Eazipay account today  …. I
          live at …….)</span
        >
      </div>
      <div>
        <span class="text-error font-bold leading-6">Important Notice</span>
        <p>
          To verify, record a well-lit, in-focus video of your face. Accepted
          formats: AVI, MP4, WEBM, up to 20MB. Record within the highlighted
          area and re-upload if necessary.
        </p>
      </div>

      <div v-if="!kycInfo" class="w-full">
        <VideoRec @video-recorded="($event) => assignFile($event)" />
      </div>

      <div
        v-else
        class="py-2 px-4 border border-dark-100 rounded-xl bg-newGrey flex items-center justify-between"
      >
        <div class="flex items-center gap-2">
          <div
            class="h-12 w-12 flex justify-center items-center bg-white rounded-full flex-shrink-0"
          >
            <img src="@/assets/icons/img-icon.svg?url" alt="" />
          </div>
          <div class="flex flex-col">
            <span class="">My Verification video (.mov) </span>
            <!-- <span class="text-dark-400 text-xs">20mb</span> -->
          </div>
        </div>

        <GetStatus bgWhite :status="kycInfo.status" />
      </div>
    </div>

    <div
      v-if="!kycInfo"
      class="flex w-full flex-col-reverse md:flex-row md:items-center gap-4 justify-between"
    >
      <easiButton
        :block="breakPoints('md')"
        variant="text"
        color="secondary"
        size="medium"
        >{{ breakPoints("md") ? "Discard Changes" : "Cancel" }}</easiButton
      >

      <div class="flex flex-col md:flex-row md:items-center md:gap-4">
        <easiButton
          @click="submit"
          variant="outlined"
          size="medium"
          class="hidden md:flex"
          >Save Update</easiButton
        >
        <easiButton @click="submit" :block="breakPoints('md')" size="medium"
          >Submit</easiButton
        >
      </div>
    </div>

    <easiRoundLoader v-if="loading" />
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>{{ "Video submitted for approval" }}</span>
      </template>
    </easiSuccess>
  </div>
</template>

<script setup>
import GetStatus from "../GetStatus.vue";

import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";
import { ref, computed } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import VideoRec from "@/components/global/VideoRecorder.vue";
const { breakPoints, uploadFileToServer } = helperFunctions;
const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();

const hasData = ref(false);
const loading = ref(false);
const updateSuccess = ref(false);
const uploadFile = ref(null);
const removeFile = ref(false);

function assignFile(fil) {
  uploadFile.value = fil;
}
const kycInfo = computed(
  () =>
    store.getKyc &&
    store.getKyc.data &&
    store.getKyc.data.identityInformation &&
    store.getKyc.data.identityInformation.find(
      (info) => info.idType === "video_verification"
    )
);
const args = ref({
  idType: "video_verification",
  front: "",
  back: "",
});
function validate() {
  if (uploadFile.value) {
    return true;
  }
  toast.error("Upload all files");
  return false;
}

const createValidIdentityInformation = async () => {
  loading.value = true;

  try {
    if (uploadFile.value) {
      args.value.front = await uploadFileToServer(uploadFile.value);
    }
    if (typeof args.value.front !== "string") {
      toast.error("Upload failed");
      return;
    }
    const data = [args.value];
    let res = await mutate({
      endpoint: "CreateValidIdentityInformation",
      data: { input: data },
      service: "SETTINGS",
    });
    if (res.success) {
      updateSuccess.value = true;
      hasData.value = !hasData.value;
    } else {
      toast.error(res.message);
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
};

async function submit() {
  if (validate()) {
    await createValidIdentityInformation();
  }
}
</script>

<style></style>
