const mutationFiles = import.meta.glob("./mutations/*.graphql");
const queryFiles = import.meta.glob("./queries/*.graphql");

export const loadMutation = async (endpoint) => {
  const mutation = mutationFiles[`./mutations/${endpoint}.graphql`];
  if (!mutation) {
    throw new Error(`Mutation ${endpoint} not found`);
  }
  const module = await mutation();
  return module.default;
};

export const loadQuery = async (endpoint) => {
  const query = queryFiles[`./queries/${endpoint}.graphql`];
  if (!query) {
    throw new Error(`Query ${endpoint} not found`);
  }
  const module = await query();
  return module.default;
};
