<template>
  <section class="md:relative md:mb-0 mb-0 request-demo-section bg-landingGrey">
    <div class="flex relative justify-start">
      <div
        class="bg-mint mt-12 md:mt-16 flex md:flex-row flex-col w-full md:w-9/12 md:mx-auto rounded-t-3xl"
      >
        <img
          style="z-index: 999"
          class="md:w-1/2 z-10 md:-mt-12 -ml-32 md:-ml-24"
          src="./assets/yc-persona.png"
          alt=""
        />
        <img
          class="md:w-72 w-56 top-0 absolute md:left-64 z-50 ml-20"
          src="./assets/ques.png"
          alt=""
        />

        <div
          class="rounded-2xl p-3 bg-white shadow-lg w-1/3 self-center md:flex hidden flex-col gap-4 justify-self-end"
        >
          <img src="./assets/ycomb.png" class="w-32 self-center" alt="" />
          <span class="text-center text-sm">
            Eazipay is backed up by Y Combinator and some <br />
            other notable Silicon Valley Entrepreneurs and <br />
            Investors
          </span>
        </div>
      </div>
    </div>
    <div style="z-index: 999" class="absolute w-full px-auto md:hidden">
      <div
        class="mx-auto shadow-lg rounded-2xl p-3 bg-white w-9/12 -mt-24 self-center flex items-center justify-center flex-col gap-4"
      >
        <img src="./assets/ycomb.png" class="w-32 self-center" alt="" />
        <span class="text-center font-medium mb-2 text-sm">
          Eazipay is backed up by Y Combinator <br />
          and some other notable Silicon Valley <br />
          Entrepreneurs and Investors
        </span>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, ref } from "vue";
import SiteInput from "../../components/SiteInput.vue";
import SiteButton from "../../components/SiteButton.vue";
import RadioButtons from "../../components/RadioButtons.vue";
import { useDataStore } from "../../../stores/data";
import SiteModal from "../../components/SiteModal.vue";

const accountType = ref("individual");

const form = reactive({
  firstName: "",
  lastName: "",
  email: "",
});

const companyForm = reactive({
  jobTitle: "",
  companySize: "",
  companyName: "",
});

const submitting = ref(false);
const showSuccess = ref(false);

const store = useDataStore();
const { query } = store;

const resetFormState = () => {
  form.firstName = "";
  form.lastName = "";
  form.email = "";

  companyForm.jobTitle = "";
  companyForm.companySize = "";
  companyForm.companyName = "";
};

const handleSubmit = async () => {
  let payload = {
    ...form,
  };
  let name;
  if (accountType.value === "company") {
    name = companyForm.companyName;
    payload = { ...payload, ...companyForm };
  } else {
    name = form.firstName + " " + form.lastName;
  }

  payload.name = name;

  try {
    submitting.value = true;
    await query({
      endpoint: "Demo",
      payload: {
        input: payload,
      },
      service: "PAYROLL",
    });
    showSuccess.value = true;
  } catch (err) {
    console.log("err - ", err);
  } finally {
    submitting.value = false;
  }
};
</script>

<style lang="scss" scoped>
.request-demo-section {
  .heading {
    color: #11453b;
  }

  .form {
    background: #ffffff;
    border: 1px solid #e7e8e7;
    box-shadow: 0px 20px 48px rgba(170, 170, 170, 0.29);
    border-radius: 32px;
    padding: 30px 32px;
    .company-fields {
      transition: max-height 0.45s ease;

      &.show {
        max-height: 220px;
      }
    }
  }
}

.reveal-enter-active,
.reveal-leave-active {
  /* max-height: 200px; */
  transition: max-height 0.45s ease;
}

.reveal-enter-from,
.reveal-leave-to {
  max-height: 0px;
}

.reveal-enter-to,
.reveal-leave-from {
  max-height: 200px;
}
</style>
