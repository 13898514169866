<template>
  <div class="md:flex hidden w-full gap-2">
    <!-- index == optionData.length - 1 ? 'clipLeft' : '',
        index == 0 ? ' clipRight pl-4' : '',
        index !== 0 && index !== optionData.length - 1 ? 'clipBoth ' : '', -->
    <div
      v-for="(opt, index) in optionData"
      :key="opt.title"
      :class="[
        activeIndex === index ? 'bg-primary' : 'bg-newGrey',
        clipEdge(index),
        index > 0 ? 'pl-10' : 'pl-6',
      ]"
      @click="setActive(index, opt)"
      style="height: 60px"
      class="py-3 pr-3 shadow-3xl w-full justify-start rounded-lg flex items-center gap-x-3 cursor-pointer"
    >
      <img
        :src="opt.complete ? complete : opt.icon ? opt.icon : company"
        alt=""
        class="object-contain"
      />

      <div
        :class="activeIndex === index ? 'text-white' : 'text-heading'"
        class="text-sm font-medium leading-4 w-24"
      >
        {{ opt && opt.title }}
      </div>
    </div>
    <!-- <div
      :class="activeIndex == 1 ? 'bg-primary' : 'bg-background'"
      @click="setActive(1)"
      class="clipLeft p-4 pl-5 rounded-lg grid grid-flow-col auto-cols-auto items-center gap-3 cursor-pointer"
    >
      <img
        :src="activeIndex == 1 ? data && data.activeIcon : data && data.icon"
        alt=""
        class="object-contain h-10"
      />

      <div
        :class="activeIndex == 1 ? 'text-tertiary' : 'text-heading'"
        class="text-sm leading-none"
      >
        {{ data && data.secondText }}
      </div>
    </div> -->
  </div>
</template>

<script setup>
import { onMounted, watch, computed, ref } from "vue";
import complete from "@/assets/icons/complete-icon.svg?url";
import company from "@/assets/icons/company-null.svg?url";

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: () => ({
      firstText: "",
      secondText: "",
      activeIcon: null,
      icon: null,
      activeIcon2: null,
      icon2: null,
      active: 0,
    }),
  },
  optionData: {
    type: Array,
    default: () => [
      {
        title: "",
        complete: false,
        active: false,
        icon: "",
        value: "",
      },
    ],
  },
  bool: {
    type: Boolean,
    default: false,
  },
  activeNum: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(["changeActive"]);

const activeIndex = ref(0);

const activeProp = computed(() => {
  return props.activeNum;
});

const clipEdge = (index) => {
  if (index == 0) {
    return "clipRight";
  } else if (index == props.optionData.length - 1) {
    return "clipLeft";
  } else {
    return "clipBoth";
  }
};

watch(activeProp, (val) => {
  activeIndex.value = val;
});

onMounted(() => {
  if (props.activeNum > 0) {
    activeIndex.value = props.activeNum;
  } else {
    activeIndex.value = 0;
  }
});
const setActive = (val, obj) => {
  console.log(val, "vvv");
  if (val == 0) {
    activeIndex.value = val;
    emit("changeActive", val);
  } else if (val > 0) {
    activeIndex.value = val;
    emit("changeActive", val);
  }
  // } else if (val > 0 && props.optionData[val - 1].complete) {
  //   activeIndex.value = val;
  //   emit("changeActive", val);
  // }
};
</script>

<style scope>
.clipLeft {
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 10% 50%);
}
.clipBoth {
  clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 8% 49%, 0% 0%);
}
.clipRight {
  clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
}
.arrow-pointer {
  width: auto;
  height: 100%;
  /* background: #11453b; */
  position: relative;
}

.arrow-pointer::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  bottom: 0;
  width: 0;
  height: 0;

  transform: translateY(-50%);

  border-left: 25px solid #ffffff;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
}

/* .arrow-pointer::before {
  content: "";
  position: absolute;
  right: -25px;
  bottom: 0;
  width: 0;
  height: 0;
  border-radius: 20px;
  border-left: 25px solid #32557f;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
} */
</style>
