<template>
  <easiModal
    v-if="isOpen"
    @close="closeModal"
    :isHeader="false"
    :isBorder="false"
    :lite="false"
    :persistence="true"
    :showCloseButton="false"
    apiDashboard
    rounded="3xl"
  >
    <form class="p-8 relative" @submit.prevent="handleSubmit">
      <div class="text-2xl font-medium text-center mb-6">
        {{ title }}
      </div>

      <img
        class="w-7 h-7 cursor-pointer absolute right-10 top-6"
        src="@/assets/icons/x.svg?url"
        alt=""
        @click="closeModal"
      />
      <div v-if="step === 1">
        <ApiUpload
          :loading="loading"
          :url="data && data.logo"
          @fileUrl="uploadFile = $event"
          label="Upload a logo to customise your product."
        />

        <div class="flex flex-col gap-0">
          <easiTextInput
            v-model="args.displayName"
            type="text"
            name="displayName"
            placeholder="Display Name"
            required
            :maxlength="20"
            :disabled="loading"
          ></easiTextInput>
          <easiTextInput
            v-model="args.name"
            type="text"
            name="productName"
            placeholder="Product Name"
            required
            :disabled="loading"
          ></easiTextInput>
          <div class="my-5">
            <easiSelectInput2
              placeholder="Select Industry"
              required
              :class="[loading ? 'pointer-events-none ' : '']"
              @update="args.industry = $event"
              :value="args.industry"
              :options="industriesList"
              :error="errorRules.industry"
            />
          </div>

          <easiButton
            :loading="loading"
            :block="true"
            color="primary"
            class="rounded-full w-full md:w-52"
          >
            <span class="text-sm">
              {{ isEdit ? "Save Update" : "Add Product" }}
            </span>
          </easiButton>
        </div>
      </div>

      <div v-else-if="step === 2">
        <div class="flex flex-col gap-0">
          <p class="text-center font-normal text-lg w-11/12 mx-auto">
            Please enter the authentication code sent to your email address.
          </p>
          <div class="mt-5">
            <span class="w-fit flex justify-around items-center">
              <easiOtp
                class="w-full sm:w-auto"
                size="10"
                :token_length="6"
                :max-length="1"
                @input="otpCode = $event"
                :error="errorRules.otpCode"
              />
            </span>
            <div class="flex justify-center gap-1 my-10 items-center">
              <p class="text-dark-800 font-bold">Did not receive Code?</p>
              <!-- <span class="text-secondary font-bold"> Resend </span> -->
              <easiButton
                type="button"
                @click="resendOTP"
                :loading="resendOtpLoading"
                variant="text"
                color="secondary"
                class="-ml-4"
              >
                <strong>Resend</strong>
              </easiButton>
            </div>
          </div>
          <hr class="mb-6" />
          <!-- <div class="mb-10">
            <p class="mb-2">
              Please select an expire date for your previous keys
            </p>
            <div class="bg-newAsh border border-outlineGray rounded-xl p-2">
              <div class="flex gap-1">
                <div
                  v-for="time in timeToggle"
                  :key="time.label"
                  class="py-2 font-medium text-sm rounded-lg uppercase cursor-pointer w-full text-center"
                  :class="
                    activetimeToggle.label === time.label
                      ? 'bg-white text-black'
                      : ' text-newGray'
                  "
                  @click="activetimeToggle = time"
                >
                  {{ time.label }}
                </div>
              </div>
            </div>
          </div> -->

          <easiButton
            :loading="loading"
            :block="true"
            color="primary"
            :disabled="!otpCode || otpCode.length < 6"
            class="rounded-full w-full md:w-52"
          >
            <span class="text-sm"> Generate Key </span>
          </easiButton>
        </div>
      </div>

      <div v-else-if="step === 3">
        <p class="text-secondary text-lg mb-8">
          Secret keys can only be viewed and downloaded once
        </p>
        <div class="w-full flex flex-col gap-4">
          <ApiInput label="Public Keys" :value="apiKeys.publicKey" />
          <ApiInput label="Security Keys" :value="apiKeys.privateKey" />
          <!-- <ApiInput
            label="Encryption Keys"
            value="frfnfje-nfjnerf-defef-efrefrfrf"
          /> -->
        </div>

        <div class="flex justify-center mt-10 w-11/12 mx-auto">
          <easiButton :loading="loading" color="primary" :disabled="isDisabled">
            <div class="flex justify-center items-center gap-2">
              <span class="text-sm"> Download API Keys </span>

              <img
                src="@/assets/icons/arrow-down-tray.svg?url"
                class="w-6 h-6"
                alt=""
              />
            </div>
          </easiButton>
        </div>
      </div>
      <div v-else-if="step === 4">
        <div class="flex flex-col gap-0">
          <p class="text-center font-normal text-lg w-11/12 mx-auto">
            Please enter the authentication code sent to your email address.
          </p>
          <div class="mt-5">
            <span class="w-fit flex justify-around items-center">
              <easiOtp
                class="w-full sm:w-auto"
                size="10"
                :token_length="6"
                :max-length="1"
                @input="otpCode = $event"
                :error="errorRules.otpCode"
              />
            </span>
            <div class="flex justify-center gap-1 my-10 items-center">
              <p class="text-dark-800 font-bold">Did not receive Code?</p>
              <!-- <span class="text-secondary font-bold"> Resend </span> -->
              <easiButton
                type="button"
                @click="resendOTP"
                :loading="resendOtpLoading"
                variant="text"
                color="secondary"
                class="-ml-4"
              >
                <strong>Resend</strong>
              </easiButton>
            </div>
          </div>
          <hr class="mb-6" />
          <div class="mb-10">
            <p class="mb-2">
              Please select an expiry date for your previous keys
            </p>
            <div class="bg-newAsh border border-outlineGray rounded-xl p-2">
              <div class="flex gap-1">
                <div
                  v-for="time in timeToggle"
                  :key="time.label"
                  class="py-2 font-medium text-sm rounded-lg uppercase cursor-pointer w-full text-center"
                  :class="
                    activetimeToggle.label === time.label
                      ? 'bg-white text-black'
                      : ' text-newGray'
                  "
                  @click="activetimeToggle = time"
                >
                  {{ time.label }}
                </div>
              </div>
            </div>
          </div>

          <easiButton
            :loading="loading"
            :block="true"
            color="primary"
            :disabled="!otpCode || otpCode.length < 6"
            class="rounded-full w-full md:w-52"
          >
            <span class="text-sm"> Generate Key </span>
          </easiButton>
        </div>
      </div>
    </form>
  </easiModal>

  <easiAlert
    v-if="thanksModal"
    @close="thanksModal = false"
    noIcon
    :showDefaultTop="false"
  >
    <div class="px-6">
      <div class="flex justify-center pt-6">
        <span>
          <img src="@/assets/img/success.gif" class="h-32" alt="" />
        </span>
        <!-- <img class="w-32" :src="success" alt="" /> -->
      </div>
      <div class="w-10/12 mx-auto text-center font-bold text-lg mt-6">
        Added Successful!
      </div>
      <p class="text-center w-11/12 mx-auto">
        Would you like to generate live API keys for this Product?
      </p>
      <main class="w-full mt-6 space-y-4">
        <easiButton :loading="loading" @click="moveToGenerateScreen" block
          >Yes, Generate
        </easiButton>
        <easiButton
          color="primary"
          variant="outlined"
          block
          class="rounded-full bg-white w-full"
          @click="viewProduct"
        >
          No, Later
        </easiButton>
      </main>
    </div>
  </easiAlert>
</template>

<script setup>
import imgIcon from "@/assets/icons/img-icon.svg?url";

import ApiInput from "@/components/ApiDashboard/ApiInput.vue";
import ApiUpload from "@/components/ApiDashboard/ApiUpload.vue";

import { computed, onMounted, reactive, ref, watch } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useRouter } from "vue-router";

const { uploadFileToServer } = helperFunctions;
const toast = useToast();
const store = useDataStore();
const { query, mutate } = store;
const router = useRouter();

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  data: null,
  setStep: Number,
  setTitle: String,
});

const emit = defineEmits(["toggleModal", "query"]);

const watchIsOpen = computed(() => props.isOpen);
const watchStep = computed(() => props.setStep);

const companyData = computed(() => store.getCompanyAdmin);

const showeasiUpload = ref(false);
const thanksModal = ref(false);
const loading = ref(false);
const resendOtpLoading = ref(false);

const otpCode = ref("");
const activetimeToggle = ref({
  label: "Now",
  value: 0,
});
const timeToggle = reactive([
  {
    label: "Now",
    value: 0,
  },
  {
    label: "1 hour",
    value: 1,
  },
  {
    label: "2 hours",
    value: 2,
  },
  {
    label: "3 hours",
    value: 3,
  },
]);

const step = ref(1); //1 2 3
const title = ref("");
const productId = ref(null);
const apiKeys = ref({
  publicKey: "",
  privateKey: "",
});

const uploadFile = ref(null);
const args = reactive({
  displayName: "",
  name: "",
  industry: "",
  logo: null,
});

const errorRules = reactive({
  userInput: false,
  otpCode: false,
  industry: false,
});

const industriesList = [
  { label: "Financial Services", value: "Financial Services" },
  { label: "Payroll and HR", value: "Payroll and HR" },
  { label: "Taxation and Accounting", value: "Taxation and Accounting" },
  { label: "Retirement and Pensions", value: "Retirement and Pensions" },
  { label: "Insurance Management", value: "Insurance Management" },
  { label: "Benefits Administration", value: "Benefits Administration" },
  { label: "Small Business Solutions", value: "Small Business Solutions" },
  { label: "Self-Employed/Contractors", value: "Self-Employed/Contractors" },
  {
    label: "Government and Public Services",
    value: "Government and Public Services",
  },
  { label: "Healthcare Administration", value: "Healthcare Administration" },
  { label: "Education Management", value: "Education Management" },
  { label: "Non-profit Organizations", value: "Non-profit Organizations" },
  { label: "Legal and Law Firms", value: "Legal and Law Firms" },
  {
    label: "Real Estate and Property Management",
    value: "Real Estate and Property Management",
  },
  { label: "E-commerce and Retail", value: "E-commerce and Retail" },
  { label: "Travel and Hospitality", value: "Travel and Hospitality" },
];

//EVENTS

watch(watchStep, () => {
  updateStep();
});

watch(
  watchIsOpen,
  () => {
    updateStep();
    assignValues();
  },
  { immediate: true }
);

onMounted(() => {
  updateStep();
  assignValues();
});

//INITIALIZATION METHODS

function assignValues() {
  const { isEdit, data } = props;
  if (!isEdit || !data) return;

  console.log(data);

  Object.keys(args).forEach((key) => {
    args[key] = data[key];
  });

  console.log("ARGS", args);
}

function updateStep() {
  step.value = props.setStep || step.value || 1;
  switch (step.value) {
    case 1:
      title.value = props.isEdit ? "Edit Product Details" : "Add New Product";
      break;
    case 2:
      title.value = props.setTitle || "Generate New API";
      break;
    case 3:
      title.value = props.setTitle || "Download Your New API Keys";
      break;
    case 4:
      title.value = props.setTitle || "Generate New API";
      break;
    case 5:
      title.value = props.setTitle || "Generate New API";
      break;
    default:
      break;
  }
}

//PRODUCT AND APIS METHODS

const handleSubmit = () => {
  switch (step.value) {
    case 1:
      handleAddUpdateProduct();
      break;
    case 2:
      handleGenerateAPI();
      break;
    case 3:
      downloadAPIKeys();
      break;
    case 4:
      handleGenerateAPI();
      break;

    default:
      break;
  }
};

const handleAddUpdateProduct = async () => {
  errorRules.industry = false;
  if (!args.industry) {
    errorRules.industry = "please select an industry";
    return;
  }

  try {
    const payload = { ...args };

    if (props.isEdit) {
      payload.productId = props.data && props.data._id;
      // delete payload.industry;
    }
    const endpoint = props.isEdit ? "UpdateProduct" : "AddNewProduct";

    loading.value = true;

    if (uploadFile.value) {
      payload.logo = await uploadFileToServer(uploadFile.value);
    }

    console.log(payload);

    const res = await mutate({
      endpoint,
      data: {
        input: payload,
      },
      service: "API",
    });

    if (res.success) {
      emit("toggleModal", false);
      clearFields();

      if (props.isEdit) {
        toast.success("Update successful");
        emit("query");
      } else {
        productId.value = res.data._id;
        thanksModal.value = true;
        queryProducts();
      }
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const handleGenerateAPI = async () => {
  if (!otpCode.value || otpCode.value.length < 6) {
    errorRules.otpCode = "OTP is required";
    return;
  }

  try {
    loading.value = true;
    const otpConfirmed = await confirmOTP();
    console.log(otpConfirmed);
    if (!otpConfirmed) return;

    const payload = {
      expiryTime: activetimeToggle.value.value,
      productId: props.isEdit ? props.data && props.data._id : productId.value,
    };

    const res = await mutate({
      endpoint: "GenerateProductApiKeys",
      data: { input: payload },
      service: "API",
    });

    if (res.success) {
      const { privateKey, publicKey } = res.data;
      apiKeys.value = {
        privateKey,
        publicKey,
      };
      step.value = 3;
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const downloadAPIKeys = () => {
  const blob = new Blob([JSON.stringify(apiKeys.value, null, 2)], {
    type: "application/json",
  });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "api_keys.txt";
  link.click();
};

//THANK YOU MODAL METHODS

const moveToGenerateScreen = async () => {
  const otpSent = await sendOTP();
  console.log(otpSent);
  if (!otpSent) return;

  thanksModal.value = false;
  step.value = 2;
  emit("toggleModal", true);
};

const viewProduct = () => {
  thanksModal.value = false;
  router.push(`/developers/dashboard/api-keys/product/${productId.value}`);
};

// OTP METHODS

const resendOTP = async () => {
  try {
    resendOtpLoading.value = true;
    const otpSent = await sendOTP(false);
    if (otpSent) {
      toast.success("OTP sent successfully");
    }
  } catch (error) {
    console.log(error);
  } finally {
    resendOtpLoading.value = false;
  }
};

const sendOTP = async (startLoading = true) => {
  const { companyAdmin } = companyData.value || {};
  if (!companyAdmin) return;

  const payload = {
    channel: "EMAIL",
    value: companyAdmin.email,
    type: "GENERATE_API_KEY",
  };
  try {
    loading.value = startLoading;
    const res = await mutate({
      endpoint: "SendOTP",
      data: { input: payload },
      service: "AUTH",
    });

    if (res.success) {
      localStorage.setItem("API_OTP_REF", res.data.reference);
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    toast.error(error.message);
    return false;
  } finally {
    loading.value = false;
  }
};

const confirmOTP = async () => {
  const { companyAdmin } = companyData.value || {};
  if (!companyAdmin) return;

  const payload = {
    code: otpCode.value,
    reference: localStorage.getItem("API_OTP_REF"),
    value: companyAdmin.email,
  };

  try {
    const res = await mutate({
      endpoint: "ConfirmOTP",
      data: { input: payload },
      service: "AUTH",
    });

    if (res.success) {
      localStorage.removeItem("API_OTP_REF");
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    toast.error(error.message);
    return false;
  }
};

//OTHERS

const clearFields = () => {
  args.displayName = "";
  args.name = "";
  args.industry = "";
  args.logo = null;
  //   args.employeeId = null;

  errorRules.userInput = false;
  errorRules.otpCode = false;
  showeasiUpload.value = false;
};

const closeModal = () => {
  step.value = 1;
  thanksModal.value = false;
  clearFields();
  emit("toggleModal", false);
};

const queryProducts = async () => {
  try {
    const { company } = companyData.value;
    if (!company) return;

    const res = await query({
      endpoint: "ListCompanyProducts",
      payload: {
        input: {
          companyId: company._id,
        },
      },
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiProducts: res.data,
      });
    }

    console.log(res);
  } catch (e) {
    console.log(e);
  }
};
</script>

<style></style>
