<template>
  <main class="md:px-0 px-3 py-4">
    <h2 class="text-base mb-2 font-bold md:text-2xl capitalize mx-3">
      Tax Filing Service
    </h2>
    <div class="mt-5 rounded-3xl back_ground p-7 pb-10">
      <div class="rounded-2xl bannerText p-4">
        <p class="text-white font-bold text-3xl">
          File your annual taxes with ease.
        </p>
        <p class="text-newLightGray text-base">
          We help you simplify the process of obtaining your tax clearance
          certificate.
        </p>
        <div class="md:mt-3">
          <div class="block md:flex gap-4">
            <div class="flex items-center gap-2">
              <div>
                <img src="../../../assets/icons/taxFilingItem.svg?url" alt="" />
              </div>
              <div class="text-sm text-white">Direct assessment </div>
            </div>
            <div class="flex items-center gap-2">
              <div>
                <img src="../../../assets/icons/taxFilingItem.svg?url" alt="" />
              </div>
              <div class="text-sm text-white">State Tax Clearance</div>
            </div>
          </div>
          <div class="block md:flex gap-4 md:mt-1">
            <div class="flex items-center gap-2">
              <div>
                <img src="../../../assets/icons/taxFilingItem.svg?url" alt="" />
              </div>
              <div class="text-sm text-white">State Tax Registration</div>
            </div>
            <div class="flex items-center gap-2">
              <div>
                <img src="../../../assets/icons/taxFilingItem.svg?url" alt="" />
              </div>
              <div class="text-sm text-white">Annual PAYE returns</div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mt-3 md:mt-4 lg:mt-5 rounded-full bg-primary lg:w-6/12 px-6 py-3"
      >
        <div
          class="flex items-center justify-center md:justify-between cursor-pointer"
          @click="$router.push('/admin/tax-filing/application')"
        >
          <p class="text-white text-base md:text-lg">
            Get started with your application now
          </p>
          <div class="hidden md:block">
            <img src="../../../assets/icons/arrow-right.svg?url" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="mt-8 border border-outlineGray bg-white mx-2 rounded-3xl px-2 md:px-6 pt-4 items-container"
    >
      <p class="font-medium text-xl pl-3 md:pl-0">Application History</p>

      <div>
        <div class="rounded-lg p-2 bg-newAsh grid grid-cols-3 mt-6">
          <div
            class="rounded-lg text-center cursor-pointer text-black py-2"
            :class="{ ' bg-white ': selectedStatus === 'Pending' }"
            @click="selectedStatus = 'Pending'"
          >
            Pending

            <span> ({{ pendingCount }}) </span>

            <!-- (1) -->
          </div>
          <div
            class="rounded-lg text-center cursor-pointer text-black py-2"
            :class="{ ' bg-white ': selectedStatus === 'Declined' }"
            @click="selectedStatus = 'Declined'"
          >
            Declined
            <span> ({{ declinedCount }}) </span>
          </div>
          <div
            class="rounded-lg text-center cursor-pointer text-black py-2"
            :class="{ ' bg-white ': selectedStatus === 'Filed' }"
            @click="selectedStatus = 'Filed'"
          >
            Filed
            <span> ({{ filedCount }}) </span>
          </div>
        </div>

        <div v-if="taxes.length">
          <div class="mt-2 border-t border-b border-newAsh flex flex-col gap-1">
            <TaxCard
              v-for="(tax, taxIndex) in taxes"
              :key="taxIndex"
              :tax="tax"
            />
          </div>
          <TaxPagination
            :totalItems="totalItems"
            :pageSize="pageSize"
            :currentPage="currentPage"
            @page-change="handlePageChange"
          ></TaxPagination>
        </div>

        <div v-else class="text-center mt-12 flex flex-col justify-center">
          <div class=" ">
            <div
              class="container mx-auto flex items-center justify-center gap-4 flex-wrap"
            >
              <img src="@/assets/img/Validation.png" class="h-32" alt="" />
            </div>
            <h3 class="text-xl text-dark-800 font-bold">No History !</h3>
            <p class="text-dark-500 text-base">
              You have not filed any tax application
            </p>
          </div>
        </div>
      </div>
    </div>

    <easiAlert
      v-if="thanksModal"
      @close="thanksModal = false"
      noIcon
      :showDefaultTop="false"
    >
      <div class="flex justify-center pt-10">
        <span>
          <img class="w-20 md:w-auto" :src="`${archiveIcon}`" alt="" />
        </span>
        <!-- <img class="w-32" :src="success" alt="" /> -->
      </div>
      <div class="w-10/12 mx-auto text-center font-medium text-lg mt-6">
        Thank you, your application has been submitted! We will be in touch with
        you soon
      </div>
      <main class="flex flex-col items-center w-full mt-6">
        <easiButton class="w-40 min-w-full" @click="thanksModal = false"
          >Okay !</easiButton
        >
      </main>
    </easiAlert>

    <easiRoundLoader v-if="setisLoading" />
  </main>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import TaxCard from "@/components/TaxFiling/TaxCard.vue";
import TaxPagination from "@/components/TaxFiling/TaxPagination.vue";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";
import archiveIcon from "@/assets/icons/archived.svg?url";
const toast = useToast();
const setisLoading = ref(false);
const taxes = ref([]);
const pageSize = ref(10);
const currentPage = ref(1);
const filedCount = ref(0);
const pendingCount = ref(0);
const declinedCount = ref(0);
const totalItems = ref(0);
const selectedStatus = ref("Pending");
const handlePageChange = (page) => {
  currentPage.value = page;
  getAllTaxForms();
};
watch(selectedStatus, () => {
  getAllTaxForms();
});
const store = useDataStore();
const { query } = store;
const thanksModal = ref(false);
const getAllTaxForms = async () => {
  try {
    setisLoading.value = true;
    const payload = {
      filter: [
        {
          field: "status",
          value: selectedStatus.value,
        },
      ],
      paging: {
        skip:
          currentPage.value === 1
            ? 0
            : pageSize.value * (currentPage.value - 1),
        limit: pageSize.value,
        search: "",
      },
    };
    let res = await query({
      endpoint: "GetTaxForm",
      payload: {
        input: payload,
      },
      service: "PAYROLL",
      storeKey: "listTaxFiling",
    });
    if (res.success) {
      taxes.value = res.data.taxForms;
      totalItems.value = res.data.count;
      pendingCount.value = res.data.pendingCount;
      filedCount.value = res.data.filedCount;
      declinedCount.value = res.data.declinedCount;
    } else {
      toast.error(res.message);
    }
  } catch (e) {
    console.log("error", e);
    toast.error(e.message);
  } finally {
    setisLoading.value = false;
  }
};
onMounted(() => {
  getAllTaxForms();
});
</script>

<style scoped>
.items-container {
  min-height: 40vh;
}
.bannerText {
  background: linear-gradient(90deg, #11453b 0%, rgba(17, 69, 59, 0) 99.14%);
}
.back_ground {
  background-image: url("../../../assets/img/taxFilingBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 768px) {
  .back_ground {
    background-image: url("../../../assets/img/taxFilingBackgroundMobile.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
