<template>
  <div class="px-4 md:px-28 pt-8">
    <p class="font-bold text-black">
      <span class="text-2xl"> API Keys / </span>

      <span> Product </span>
    </p>

    <div class="border border-outlineGray p-8 mt-10">
      <div class="flex items-center justify-between">
        <p class="text-grey text-lg">Your Products</p>
        <div
          v-if="userProfile && userProfile.permission.includes('ADD_PRODUCT')"
          class="bg-primary text-white py-3 px-5 rounded-full flex items-center gap-2 font-medium cursor-pointer"
          @click="isOpen = true"
        >
          <img src="@/assets/icons/Whiteplus.svg?url" alt="" />

          Add New Product
        </div>
      </div>
      <hr class="my-6" />
      <div v-if="products.length">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div
            v-for="(product, productIndex) in products"
            :key="productIndex"
            class="border cursor-pointer border-outlineGray p-4 rounded-2xl flex justify-between"
            @click="
              $router.push(
                `/developers/dashboard/api-keys/product/${product._id}`
              )
            "
          >
            <div class="flex gap-4">
              <img
                :src="product.logo || dummyImage"
                class="rounded-lg w-14 h-14 object-cover flex-shrink-0"
                alt=""
              />
              <div class="flex flex-col justify-between">
                <p class="font-medium text-lg text-dark-800">
                  {{ product.name }}
                </p>
                <div class="flex items-center gap-2">
                  <p class="text-dark-800 text-xs md:text-sm">Created on</p>
                  <p class="text-dark-200 text-sm md:text-md">
                    {{ formatDate(product.createdAt) }}
                  </p>
                </div>
              </div>
            </div>

            <div class="flex flex-col justify-start">
              <div
                v-if="product.privateKey"
                class="rounded-md px-3 py-1 bg-lighterGreen text-primary"
              >
                Live
              </div>
              <div v-else class="rounded-md px-3 py-1 bg-lighterRed text-red">
                Inactive
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div
          class="rounded-lg flex flex-col justify-center items-center bg-newAsh py-4"
        >
          <div
            class="p-5 rounded-lg bg-mint h-14 w-14 cursor-pointer"
            @click="isOpen = true"
          >
            <img src="@/assets/icons/greenAdd.svg?url" alt="" />
          </div>
          <p class="text-grey text-sm mt-4">Click here to Add new Product</p>
        </div>
      </div>

      <p class="mt-6 text-sm text-grey">
        We prioritise your account security. Secret keys can only be viewed and
        downloaded once. Replace old keys with new ones immediately for your
        account's safety
      </p>
    </div>

    <AddEditProduct
      :isOpen="isOpen"
      @toggleModal="
        isOpen = $event;
        queryProducts();
      "
    />
    <easiRoundLoader v-if="loading" />
  </div>
</template>

<script setup>
import dummyImage from "@/assets/img/greenLogo.png";
import AddEditProduct from "@/components/ApiDashboard/AddEditProduct.vue";

import { ref, reactive, onMounted, computed } from "vue";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";

const { formatDate } = helperFunctions;
const store = useDataStore();
const { query, mutate } = store;

const userProfile = computed(() => store.apiProfile);
const companyData = computed(() => store.getCompanyAdmin);
const products = computed(() => store.getAllproducts || []);
console.log("PRODUCTS", products.value);

const loading = ref(false);
const isOpen = ref(false);

onMounted(() => {
  queryProducts();
});

const queryProducts = async () => {
  try {
    const { company } = companyData.value;
    if (!company) return;
    loading.value = true;
    const res = await query({
      endpoint: "ListCompanyProducts",
      payload: {
        input: {
          companyId: company._id,
        },
      },
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiProducts: res.data,
      });
    }

    console.log(res);
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped></style>
