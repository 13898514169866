<template>
  <main>
    <section>
      <div
        ref="receiptContent"
        style="border-radius: 16px"
        class="bg-white w-full grid grid-cols-1 gap-2 mt-6 mb-2 pb-6 px-5"
      >
        <div
          class="grid grid-cols-1 gap-3 bg-dark-550 my-5 pt-5 pb-4 justify-items-center justify-center border-b border-outline"
          style="border-radius: 16px"
        >
          <span class="text-2xl text-heading font-bold">{{
            formatAmount(Number(receiptInfo.amount))
          }}</span>

          <div
            :class="
              trxClass(
                receiptInfo &&
                  receiptInfo.status &&
                  receiptInfo.status.toLowerCase()
              )
            "
            class="bg-white shadow-md capitalize rounded-full text-xs py-2 px-3"
          >
            {{
              (receiptInfo &&
                receiptInfo.status &&
                receiptInfo.status.toLowerCase()) ||
              "processing"
            }}
          </div>
          <!-- <span class="text-heading text-sm">{{ receiptData.date }}</span> -->
        </div>

        <div
          v-for="(value, key) in visibleData"
          :key="key"
          class="grid grid-flow-col auto-cols-auto w-full justify-between mr-6 text-bodyDark text-base mt-4"
        >
          <!-- <span class="capitalize hidden">{{ formatString(key) }}:</span>
            <span class="capitalize hidden">{{
              useAmount.includes(key) ? formatAmount(Number(receiptData.listDetails[key])) : receiptData.listDetails[key]
            }}</span> -->

          <span style="color: #888888" class="text-xs capitalize">{{
            key
          }}</span>
          <span
            :class="
              key === 'Amount'
                ? receiptInfo.transactionType === 'CREDIT'
                  ? 'text-success font-bold'
                  : 'text-error font-bold'
                : 'text-primary-deep'
            "
            class="capitalize text-xs text-right"
            >{{ value }}</span
          >
        </div>
      </div>

      <div
        class="grid grid-cols-1 gap-8 w-full mx-auto bg-white pt-5 items-center pb-5 shadow-sm px-3 mt-6 text-left justify-between"
        style="border-radius: 16px"
      >
        <div
          v-if="
            receiptInfo &&
            receiptInfo.status &&
            receiptInfo.status.toLowerCase() === 'successful'
          "
          class="flex justify-end gap-4 w-full"
        >
          <!-- <easiButton
            @click="shareFile"
            :loading="shareLoading"
            color="tertiary"
            class="text-sm text-primary text-left w-full"
            expand="block"
            type="submit"
            shape="round"
          >
            <div class="text-left">
              <img src="@/assets/icon/share-icon-new.svg?url" class="mr-1 inline-block" alt="" />
              <span class="text-left text-primary"> Share Receipt</span>
            </div>
          </easiButton> -->

          <easiButton
            @click="downloadWalletReceipt"
            :loading="downloadLoading"
            color="mint"
            class="relative text-sm bg-mint justify-self-end text-primary rounded-full p-3 focus:outline-none w-64 text-center"
            expand="block"
            type="submit"
          >
            <img
              src="@/assets/icons/download-icon-new.svg?url"
              class="-mt-1 mr-2 inline-block"
              alt=""
            />
            <span class="font-medium text-sm text-primary">Download</span>
          </easiButton>
        </div>

        <div v-else class="grid grid-flow-col auto-cols-auto gap-4 w-full">
          <button
            @click="openChat"
            color="errorLight"
            class="focus:outline-none text-sm justify-self-end bg-errorLight rounded-full p-3 text-left w-64"
            expand="block"
            type="submit"
            shape="round"
          >
            <div class="text-center">
              <span class="text-center text-sm text-error-trx font-medium"
                >Report transaction</span
              >
            </div>
          </button>
        </div>
        <!-- <easiButton @click="$router.push({ name: 'Payments' })"
            >Back to Payment</easiButton
          > -->
      </div>
    </section>

    <easiLoader v-if="receiptLoading" />
  </main>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";

// import html2pdf from "html2pdf.js";

import { useDataStore } from "@/stores/data.js";
const store = useDataStore();

const { query, resetVariable } = store;
const { formatAmount, downloadBase64 } = helperFunctions;
const route = useRoute();
const receiptData = computed(() => store.getReceiptPreview);

const props = defineProps(["receiptInfo"]);
const trxClass = (sts) => {
  const clx = {
    success: "text-success",
    successful: "text-success",
    processing: "text-info",
    failed: "text-error-trx",
  };
  return clx[sts] || "text-info";
};
// const hiddenFields = ref([
//   "bank_code",
//   "beneficiary",
//   "type",
//   "recurring",
//   "frequency",
//   "item_code",
//   "biller_code",
//   "reference",
// ]);

onBeforeRouteLeave(() => {
  props.receiptInfo = {};
  resetVariable("receiptPreview");
});
function getDateString(utc) {
  const days = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thur: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday",
  };

  const months = {
    Jan: "January",
    Feb: "February",
    Mar: "March",
    Apr: "April",
    May: "May",
    Jun: "June",
    Jul: "July",
    Aug: "August",
    Sep: "September",
    Oct: "October",
    Nov: "November",
    Dec: "December",
  };
  const dateArr = utc.split(" ");
  const dayOfWeek = days[dateArr[0]];
  const month = months[dateArr[1]];
  const dayAndYear = `${dateArr[2]}, ${dateArr[3]}`;
  const time = dateArr[4].slice(0, -3);

  const fullDate = ` ${month} ${dayAndYear}`;

  return {
    fullDate,
    time,
  };
}

const visibleData = computed(() => {
  const data =
    props.receiptInfo && Object.keys(props.receiptInfo).length
      ? {
          "Session ID": props.receiptInfo.sessionId || "N/A",
          "Date & Time": `${
            getDateString(props.receiptInfo.transactionDate).fullDate
          } | ${getDateString(props.receiptInfo.transactionDate).time}`,
          // "Sender name": props.receiptInfo.sender || "N/A",
          "Beneficiary account":
            `${props.receiptInfo.beneficiaryBank} | ${props.receiptInfo.beneficiaryAccountNumber}` ||
            "N/A",
          "Recipient name": props.receiptInfo.receipient || "N/A",
          Amount: formatAmount(props.receiptInfo.amount),
          Description: props.receiptInfo.description,
          // "Payment method": props.receiptInfo.paymentMethod,
          "Transaction reference": props.receiptInfo.reference,
        }
      : {};
  return data;
});

function openChat() {
  // Trigger the Tawk.to chat
  console.log(Tawk_API, "TAWK");
  if (typeof Tawk_API !== "undefined") {
    Tawk_API.toggle();
  }
}

const receiptOfBill = computed(() =>
  store.getBillReceipt ? store.getBillReceipt.data : {}
);

// const useAmount = ref(["amount", "transaction_Fee", "total_Amount"]);
const downloadLoading = ref(false);
const shareLoading = ref(false);
const receiptContent = ref(null);

// async function downloadBillReceipt() {
//   try {
//     let res = await query({
//       endpoint: "DownloadBillReceipt",
//       payload: { billPaymentId: route.query.id },
//       service: "PAYROLL",
//       storeKey: "billReceipt",
//     });
//     if (!res.success) {
//       toast.error(res.message);
//     }
//   } catch (e) {
//     console.log(e);
//     toast.error(e.message);
//   }

// }

async function downloadWalletReceipt() {
  downloadLoading.value = true;
  try {
    let res = await query({
      endpoint: "DownloadWalletReport",
      payload: {
        input: {
          id: props.receiptInfo.id,
          type: props.receiptInfo.type,
        },
      },
      service: "PAYROLL",
      // storeKey: "billReceipt",
    });
    if (res.success) {
      downloadBase64(res.data.base64, res.data.fileName);
    } else {
      toast.error(res.message);
    }
    // console.log(receiptOfBill.value, "TEMP");
  } catch (e) {
    console.log(e);
    toast.error(e.message);
  } finally {
    downloadLoading.value = false;
  }
}

const receiptLoading = ref(false);
function closeLoader() {
  downloadLoading.value = false;
  shareLoading.value = false;
  receiptLoading.value = false;
}

// const generatePDF = async (action) => {
//   if (action === "downloadFiles") {
//     downloadLoading.value = true;
//   } else {
//     shareLoading.value = true;
//   }
//   // let content = [];
//   // content.push(receiptContent.value);
//   // console.log(content, typeof content);

//   try {
//     if (receiptData.value && receiptData.value.listDetails && receiptData.value.listDetails.type) {
//       await downloadPaymentReceipt();
//     } else {
//       await downloadBillReceipt();
//     }
//     const fileName = receiptOfBill.value.fileName;
//     const pdfData = receiptOfBill.value.base64;
//     // const fileName = `receipt-${receiptData.value.listDetails.account_No}.pdf`;

//     // const pdfData = await html2pdf()
//     //   .set({
//     //     margin: 5,
//     //     useCORS: true,
//     //     filename: fileName,
//     //     image: { type: "jpeg", quality: 0.98 },
//     //     html2canvas: { scale: 2 },
//     //     jsPDF: {
//     //       unit: "mm",
//     //       format: "a4",
//     //       orientation: "portrait",
//     //     },
//     //   })
//     //   .from(receiptContent.value)
//     //   .toPdf()
//     //   .output("datauristring");

//     // console.log(pdfData, "DD");
//     // Convert the PDF data to base64
//     // const pdfBase64 = btoa(pdfData);

//     if (action && action === "downloadFiles") {
//       // Write the PDF file to the device's Documents directory
//       const directory = Directory.Documents;
//       await Filesystem.writeFile({
//         path: fileName,
//         data: pdfData,
//         directory,
//       });

//       const fileUri = await Filesystem.getUri({
//         directory,
//         path: fileName,
//       });

//       // toast.error("File URI");

//       // Open the PDF file in the device's default viewer
//       await FileOpener.open({
//         filePath: fileUri.uri,
//         contentType: "application/pdf",
//       });

//       toast.error("File opened successfully");
//     } else if (action && action === "share") {
//       const directory = Directory.Cache;
//       await Filesystem.writeFile({
//         path: fileName,
//         data: pdfData,
//         directory,
//       });

//       const fileUri = await Filesystem.getUri({
//         directory,
//         path: fileName,
//       });

//       await shareText({
//         title: fileName,
//         // text: fileName,
//         url: fileUri.uri,
//       });
//     }
//   } catch (e) {
//     toast.error(e);
//     console.log(e);
//   }
// };

async function download() {
  await generatePDF("downloadFiles");

  closeLoader();
}

const shareFile = async () => {
  await generatePDF("share");
  closeLoader();
};

// function formatString(str) {
//   if (str) {
//     const arr = str.split("_");
//     const formatted = arr.length > 1 ? `${arr[0]} ${arr[1]}` : arr[0];
//     return formatted;
//   }
// }

// const receiptInfo = ref({});
// async function queryPaymentDetails() {
//   receiptLoading.value = true;
//   try {
//     const res = await query({
//       endpoint: "GetPayment",
//       payload: { paymentId: route.query.id, type: route.query.type },

//       service: "PAYROLL",
//       // storeKey: "listPayments",
//     });
//     if (res.success) {
//       props.receiptInfo = res.data;
//     }
//   } catch (err) {
//     console.log(err);
//   } finally {
//     receiptLoading.value = false;
//   }
// }
// queryPaymentDetails();

// onMounted(async () => {
//   await queryPaymentDetails();
// });
</script>

<style>
ion-content {
  --background: #f8f8f8;
}
.background {
  background: #ffffff;
  background-size: 100% 100%;
  background-repeat: repeat;
  background-position: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 4px 0;
  border: 2px solid v-bind(loaderColor);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: v-bind(loaderColor) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
