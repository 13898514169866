<template>
  <div>
    <table v-if="apiCalls.length" class="min-w-full bg-white border-gray-300">
      <thead>
        <tr class="text-left uppercase">
          <th class="py-5 px-4 border-b">S/N</th>
          <th class="py-5 px-4 border-b">product name</th>
          <th class="py-5 px-4 border-b">staff no.</th>
          <th class="py-5 px-4 border-b">Service type</th>
          <th class="py-5 px-4 border-b">amount paid</th>
          <th class="py-5 px-4 border-b">date</th>
          <th class="py-5 px-4 border-b">Status</th>
          <th class="py-5 px-4 border-b"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(api, index) in apiCalls" :key="api._id" class="">
          <td class="py-5 px-4 border-b">{{ index + 1 }}</td>
          <td class="py-5 px-4 border-b">{{ api.product.name }}</td>
          <td class="py-5 px-4 border-b">{{ api.totalStaffNo }}</td>
          <td class="py-5 px-4 border-b">
            {{ api.serviceType.split("_").join(" ") }}
          </td>
          <td class="py-5 px-4 border-b">{{ formatAmount(api.amountPaid) }}</td>
          <td class="py-5 px-4 border-b">
            {{ formatDateString(api.createdAt) }}
          </td>
          <td class="py-5 px-4 border-b">
            <span
              :class="
                api.status === 'PROCESSING'
                  ? 'text-lightBorder'
                  : api.status === 'FAILED'
                  ? 'text-error'
                  : 'text-success'
              "
            >
              {{ api.status }}
            </span>
          </td>
          <td class="py-5 px-4 border-b">
            <div
              class="bg-highlight cursor-pointer p-2 flex justify-center gap-4 rounded-2xl"
              @click="
                $router.push(
                  `/developers/dashboard/api-keys/service/${api._id}`
                )
              "
            >
              <div>View</div>
              <img src="@/assets/icons/chevron-double-right.svg?url" alt="" />
            </div>
          </td>
        </tr>
        <!-- <tr class="">
          <td class="py-5 px-4 border-b">02</td>
          <td class="py-5 px-4 border-b">WorknProsper</td>
          <td class="py-5 px-4 border-b">22</td>
          <td class="py-5 px-4 border-b">Pension</td>
          <td class="py-5 px-4 border-b">100,000,000.00</td>
          <td class="py-5 px-4 border-b">01 Mar 2023</td>
          <td class="py-5 px-4 border-b">
            <span class="text-error"> Failed </span>
          </td>
          <td class="py-5 px-4 border-b">
            <div
              class="bg-highlight cursor-pointer p-2 flex justify-center gap-4 rounded-2xl"
              @click="
                $router.push('/developers/dashboard/api-keys/service/838383838')
              "
            >
              <div>View</div>
              <img src="@/assets/icons/chevron-double-right.svg?url" alt="" />
            </div>
          </td>
        </tr>
        <tr class="">
          <td class="py-5 px-4 border-b">03</td>
          <td class="py-5 px-4 border-b">Eazipay Stores</td>
          <td class="py-5 px-4 border-b">22</td>
          <td class="py-5 px-4 border-b">Pension</td>
          <td class="py-5 px-4 border-b">100,000,000.00</td>
          <td class="py-5 px-4 border-b">01 Mar 2023</td>
          <td class="py-5 px-4 border-b">
            <span class="text-lightBorder"> Processing </span>
          </td>
          <td class="py-5 px-4 border-b">
            <div
              class="bg-highlight cursor-pointer p-2 flex justify-center gap-4 rounded-2xl"
              @click="
                $router.push('/developers/dashboard/api-keys/service/838383838')
              "
            >
              <div>View</div>
              <img src="@/assets/icons/chevron-double-right.svg?url" alt="" />
            </div>
          </td>
        </tr> -->

        <!-- Add more rows as needed -->
      </tbody>
    </table>
    <div v-else class="flex flex-col items-center gap-4 p-4">
      <img src="@/assets/icons/emptyTable.svg?url" alt="" />
      <span class="text-center font-semibold">No API Call History!</span>
      <easiButton v-if="showAddNewButton" @click="isOpen = true" size="small">
        <span
          class="py-1 px-2 text-base flex gap-x-2 items-center justify-center"
        >
          <i class="pi pi-plus" />
          Create a product and generate an API Key
        </span>
      </easiButton>
    </div>
  </div>

  <AddEditProduct :isOpen="isOpen" @toggleModal="isOpen = $event" />
</template>

<script setup>
import AddEditProduct from "@/components/ApiDashboard/AddEditProduct.vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { computed, ref, watch } from "vue";
const store = useDataStore();
const { formatAmount, formatDateString } = helperFunctions;

const props = defineProps({
  data: {
    type: Object,
    // required: true,
  },
  showAddNewButton: {
    type: Boolean,
    default: false,
  },
});

const watchData = computed(() => props.data);
const isOpen = ref(false);

const apiCalls = ref([]);

watch(watchData, () => {
  apiCalls.value = props.data || [];
});
</script>

<style lang="scss" scoped></style>
