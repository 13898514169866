<template>
  <nav class="site-nav py-5 md:py-6">
    <div class="l-container flex items-center justify-between">
      <RouterLink to="/" class="nav-logo inline-block mr-12">
        <Logo />
      </RouterLink>
      <div class="hidden lg:flex">
        <ul class="nav-links items-center gap-6">
          <li>
            <router-link class="nav-link" to="/about-us">About us</router-link>
          </li>
          <li>
            <MegaMenu :menuItems="ProductMenuItems">
              <template #default="slotProps">
                <a
                  @click="slotProps.toggleMenu()"
                  class="nav-link cursor-pointer flex gap-1.5"
                >
                  Product
                  <img src="./icons/dropDown.svg?url" />

                  <!-- <i
                    :class="[
                      slotProps.showMenu ? 'pi-angle-down' : 'pi-angle-right',
                    ]"
                    class="pi text-xs"
                  ></i> -->
                </a>
              </template>
            </MegaMenu>
          </li>

          <li>
            <button
              @click="showCalculator = true"
              class="bg-none focus:outline-none nav-link"
            >
              Set your payroll
            </button>
          </li>

          <!-- <li class="relative" @mouseover="showDiv">
            <RouterLink
              class="nav-link flex gap-1.5"
              to="/developers"
              @mouseenter="showDiv"
              @mouseleave="hideDiv"
              @focusout="hideDiv"
              >Developers
              <img src="./icons/dropDown.svg?url" />
            </RouterLink>
            <div
              @click="hideDiv"
              :class="!isHovered ? 'pointer-events-none' : ''"
              class="bg-transparent fixed inset-0"
              style="z-index: 51"
            ></div>
            <div
              v-show="isHovered"
              @mouseenter="showDiv"
              @mouseleave="hideDiv"
              @focusout="hideDiv"
              tabindex="0"
              style="z-index: 52"
              class="absolute over z-10 bg-white top-7 flex flex-col gap-2 rounded-xl p-3 text-base font-normal w-fit"
            >
              <div class="bg-white rounded-xl ">
                <div
                  v-for="(menu, i) in DeveloperMenuItems"
                  :key="i"
                  @mouseover="hoveringItem = menu.title"
                  @mouseout="hoveringItem = null"
                  @click="
                    $router.push(menu.routes);
                    showMenu = false;
                  "
                  :class="[activeLink == menu.routes ? 'bg-highlight' : '']"
                  class="flex items-center cursor-pointer group w-fit gap-4 p-4 rounded-xl hover:bg-highlight"
                >
                  <div
                    :class="[
                      activeLink == menu.routes ? 'bg-white' : 'bg-lightGreyBg',
                    ]"
                    class="w-12 h-12 rounded-lg group-hover:bg-white flex-shrink-0 flex items-center justify-center"
                  >
                    <img
                      :src="
                        hoveringItem === menu.title || activeLink == menu.routes
                          ? menu.iconActive
                          : menu.icon
                      "
                      alt=""
                    />
                  </div>

                  <div class="flex flex-col">
                    <h3 class="text-base font-semibold text-dark-900">
                      {{ menu.title }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </li> -->
        </ul>
      </div>

      <div class="hidden lg:flex items-center">
        <div class="nav-btns ml-auto">
          <RouterLink to="/login" class="login-btn">Sign in</RouterLink>
          <RouterLink to="/register" class="register-btn" target="_blank"
            >Sign up</RouterLink
          >
          <!-- <RouterLink to="/register" class="register-btn btn-shadow"
            >Register</RouterLink
          > -->
        </div>
      </div>
      <div class="lg:hidden flex flex-1 justify-end">
        <button class="hamburger" @click="openMobileNav">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>

    <div
      class="mobile-nav-overlay fixed left-0 top-0 right-0 bottom-0"
      :class="{ show: show === true }"
      @click.self="closeMobileNav"
    ></div>
    <div class="mobile-nav-menu" :class="{ open: show === true }">
      <div class="pb-12">
        <div
          class="l-container flex pt-8 md:py-6 items-center justify-between mb-10"
        >
          <Logo />
          <div>
            <button
              class="mobile-menu-close-btn p-2 hover:opacity-60"
              @click="closeMobileNav"
            >
              <CloseIcon />
            </button>
          </div>
        </div>
        <div
          :style="{
            backgroundImage: ' url(' + background + ')',
          }"
          style="
            background-size: contain;
            background-position: center;
            background-repeat: repeat-x;
            scrollbar-width: none;
          "
          class="pt-4 px-4 pb-56 h-screen overflow-auto"
        >
          <p class="font-bold text-sm text-grey mb-4">PRODUCTS</p>
          <div
            style="padding: 12px"
            class="bg-white rounded-xl mb-7 flex flex-col gap-4"
          >
            <router-link to="/about-us">
              <h3
                style="padding: 12px"
                class="text-base font-semibold text-dark-900"
              >
                About Us
              </h3></router-link
            >
            <button
              class="bg-none focus:outline-none text-left"
              @click="showCalculator = true"
            >
              <h3
                style="padding: 12px"
                class="text-base font-semibold text-dark-900"
              >
                Set your payroll
              </h3>
            </button>
          </div>
          <div style="padding: 12px" class="bg-white rounded-xl mb-7">
            <div
              v-for="(menu, i) in ProductMenuItems"
              :key="i"
              @mouseover="hoveringItem = menu.title"
              @mouseout="hoveringItem = null"
              @click="
                menu.external
                  ? openLink(menu.routes)
                  : $router.push(menu.routes);
                showMenu = false;
              "
              :class="[
                activeLink == menu.routes
                  ? 'bg-highlight'
                  : menu.external
                  ? 'hidden'
                  : undefined,
              ]"
              class="flex cursor-pointer group w-fit gap-4 p-4 rounded-xl hover:bg-highlight"
            >
              <div
                :class="[
                  activeLink == menu.routes ? 'bg-white' : 'bg-lightGreyBg',
                ]"
                class="w-12 h-12 rounded-lg group-hover:bg-white flex-shrink-0 flex items-center justify-center"
              >
                <img
                  :src="
                    hoveringItem === menu.title || activeLink == menu.routes
                      ? menu.iconActive
                      : menu.icon
                  "
                  alt=""
                />
              </div>

              <div class="flex flex-col">
                <h3 class="text-base font-semibold text-dark-900">
                  {{ menu.title }}
                </h3>
                <p class="text-sm text-dark-600 line-clamp-2">
                  {{ menu.description }}
                </p>
              </div>
            </div>
          </div>
          <SiteButton
            color="primary w-full"
            shadow
            @click="openMobile('eazipay')"
          >
            Download Eazipay mobile app</SiteButton
          >
          <hr class="my-5" />

          <p class="font-medium text-sm text-grey mb-4">EMPLOYEES</p>
          <div class="bg-white rounded-xl mb-7">
            <div
              v-for="(menu, i) in EmployeeMenuItems"
              :key="i"
              @mouseover="hoveringItem = menu.title"
              @mouseout="hoveringItem = null"
              @click="
                $router.push(menu.routes);
                showMenu = false;
              "
              :class="[activeLink == menu.routes ? 'bg-highlight' : '']"
              class="flex cursor-pointer group w-fit gap-4 p-4 rounded-xl hover:bg-highlight"
            >
              <div
                :class="[
                  activeLink == menu.routes ? 'bg-white' : 'bg-lightGreyBg',
                ]"
                class="w-12 h-12 rounded-lg group-hover:bg-white flex-shrink-0 flex items-center justify-center"
              >
                <img
                  :src="
                    hoveringItem === menu.title || activeLink == menu.routes
                      ? menu.iconActive
                      : menu.icon
                  "
                  alt=""
                />
              </div>

              <div class="flex flex-col">
                <h3 class="text-base font-semibold text-dark-900">
                  {{ menu.title }}
                </h3>
                <p class="text-sm text-dark-600 line-clamp-2">
                  {{ menu.description }}
                </p>
              </div>
            </div>
          </div>
          <SiteButton color=" tertiary w-full" shadow @click="openMobile">
            Download WorknProsper</SiteButton
          >
          <hr class="my-8" />
          <!-- <p class="font-medium text-sm text-grey mb-4">Developers</p>
          <div class="bg-white rounded-xl mb-7">
            <div
              v-for="(menu, i) in DeveloperMenuItems"
              :key="i"
              @mouseover="hoveringItem = menu.title"
              @mouseout="hoveringItem = null"
              @click="
                $router.push(menu.routes);
                showMenu = false;
              "
              :class="[activeLink == menu.routes ? 'bg-highlight' : '']"
              class="flex items-center cursor-pointer group w-fit gap-4 p-4 rounded-xl hover:bg-highlight"
            >
              <div
                :class="[activeLink == menu.routes ? 'bg-white' : 'bg-lightGreyBg']"
                class="w-12 h-12 rounded-lg group-hover:bg-white flex-shrink-0 flex items-center justify-center"
              >
                <img
                  :src="hoveringItem === menu.title || activeLink == menu.routes ? menu.iconActive : menu.icon"
                  alt=""
                />
              </div>

              <div class="flex flex-col">
                <h3 class="text-base font-semibold text-dark-900">
                  {{ menu.title }}
                </h3>
              </div>
            </div>
          </div> -->

          <!-- <hr class="my-8" /> -->

          <SiteButton color="tertiary w-full" shadow to="/login">
            Sign in
          </SiteButton>

          <!-- <ul class="grid gap-4">
            <li>
              <RouterLink class="nav-link" to="/">Home</RouterLink>
            </li>
            <li>
              <RouterLink class="nav-link" to="/individual"
                >Individual</RouterLink
              >
            </li>
            <li>
              <RouterLink class="nav-link" to="/business">Business</RouterLink>
            </li>
         
            <li class="mt-6">
              <RouterLink class="nav-link" to="/login">Login</RouterLink>
            </li>
            <li class="mt-10">
              <RouterLink class="btn-shadow w-full" to="/register"
                >Register</RouterLink
              >
            </li>
          </ul> -->
        </div>
      </div>
    </div>

    <easiModal
      v-if="showCalculator"
      @close="showCalculator = false"
      :lite="true"
      :persistence="false"
      :hideBackDrop="hideBackDrop"
      :noclick="noclick"
    >
      <PayrollCalculator
        :forModal="true"
        @close="showCalculator = false"
        @popup="
          ($event) => {
            hideBackDrop = $event;
            noclick = $event;
          }
        "
      />
    </easiModal>
  </nav>
</template>

<script setup>
import MegaMenu from "./MegaMenu.vue";

import individualIcon from "./assets/individual.svg?url";
import individualActive from "./assets/individual-active.svg?url";
import hrIcon from "./assets/hr.svg?url";
import hrActive from "./assets/hr-active.svg?url";
import businessIcon from "./assets/business.svg?url";
import businessActive from "./assets/business-active.svg?url";
import payeIcon from "./assets/paye.svg?url";
import payeActive from "./assets/paye-active.svg?url";
import assessmentIcon from "./assets/assessment.svg?url";
import assessmentActive from "./assets/assessment-active.svg?url";
import deviceIcon from "./assets/device.svg?url";
import wnpIcon from "./assets/wnp.svg?url";
import wnpActive from "./assets/wnpActive.svg?url";
import deviceActive from "./assets/device-active.svg?url";
import overviewIcon from "./assets/overview.svg?url";
import overviewActive from "./assets/overview-active.svg?url";
import apiDocumentation from "./assets/apiDocumentation.svg?url";
import apiDocumentationActive from "./assets/apiDocumentationActive.svg?url";
import background from "./assets/mobileBackground.png";
import SiteButton from "./SiteButton.vue";
import PayrollCalculator from "../pages/business/PayrollCalculator.vue";

import Logo from "./Logo.vue";
import CloseIcon from "./icons/Close.vue";
const hoveringItem = ref(null);

import { computed, ref } from "vue";

const show = ref(false);
const noclick = ref(false);
const hideBackDrop = ref(false);
const isHovered = ref(false);
const showCalculator = ref(false);
const closeMobileNav = () => (show.value = false);
const openMobileNav = () => (show.value = true);
const emit = defineEmits(["openModal"]);
const showDiv = () => {
  isHovered.value = true;
};
const activeLink = ref("");
const openMobile = async (service) => {
  // window.open(
  //   "https://apps.apple.com/ng/app/eazipay-business/id6446281426",
  //   "_blank"
  // );
  // Detect the user's platform (iOS or Android)
  //  const isIOS =
  //  (/iPad|iPhone|iPod/.test(navigator.userAgent) ||
  //         navigator.userAgent.indexOf("Apple") != -1) &&
  //       !window.MSStream;
  const isIOS =
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/Mac/i);
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.includes("android");

  // Define the URLs for the App Store and Play Store
  const appStoreUrl =
    service === "eazipay"
      ? "https://apps.apple.com/us/app/eazipay-business/id6446281426"
      : "https://apps.apple.com/ng/app/worknprosper/id6451487138";
  const playStoreUrl =
    service === "eazipay"
      ? "https://play.google.com/store/apps/details?id=com.myeazipay.business"
      : "https://i.diawi.com/JyykFB";

  // Open the respective store based on the user's platform
  if (isIOS) {
    window.location.href = appStoreUrl;
  } else if (isAndroid) {
    window.location.href = playStoreUrl;
  }
  // else {
  //   // Handle other platforms if needed
  //   console.warn("Unsupported platform");
  // }
};
const hideDiv = () => {
  isHovered.value = false;
};

const ProductMenuItems = computed(() => {
  return [
    {
      title: "Individuals",
      description:
        "Add your service providers, personal assistants, chauffeurs, chefs, and more to your Eazipay and pay all in one click!",
      routes: "/individual",
      routeName: null,
      icon: individualIcon,
      iconActive: individualActive,
    },
    {
      title: "Business",
      description: `Add your employee the simple way you know and run their payroll.All in seconds!`,
      routes: "/business",
      routeName: null,
      icon: businessIcon,
      iconActive: businessActive,
    },
    {
      title: "HR Stores",
      description:
        "The all-inclusive platform to buy or rent company assets and employee perks, curated to ensure the happiness of your staff",
      routes: "/hr-stores",
      routeName: null,
      icon: hrIcon,
      iconActive: hrActive,
    },

    {
      title: "Annual PAYE Filing",
      description: `Beat the deadline and save N500,000 in tax penalties.`,
      routes: "/paye",
      routeName: null,
      icon: payeIcon,
      iconActive: payeActive,
    },
    {
      title: "Assessment",
      description: `Use our world-class online exam management tool with AI-enabled proctoring tool for all your recruitment needs and career interests or job role tests`,
      routes: "/assess-employee",
      routeName: null,
      icon: assessmentIcon,
      iconActive: assessmentActive,
    },
    {
      title: "Device Management",
      description: `Configure and secure employees’ gadgets and devices from one dashboard`,
      routes: "/manage-employee",
      routeName: null,
      icon: deviceIcon,
      iconActive: deviceActive,
    },
    {
      title: "WorknProsper",
      description: `Work Smarter, Prosper Faster, and Achieve Financial Freedom With WorknProsper.`,
      routes: "https://worknprosper.com",
      external: true,
      routeName: null,
      icon: wnpIcon,
      iconActive: wnpActive,
    },
  ];
});

const openLink = (url) => {
  if (url) {
    window.open(url, "_blank");
  }
};

const EmployeeMenuItems = computed(() => {
  return [
    {
      title: "WorknProsper",
      description: `Work Smarter, Prosper Faster, and Achieve Financial Freedom With WorknProsper.`,
      routes: "https://worknprosper.com",
      routeName: null,
      external: true,
      icon: wnpIcon,
      iconActive: wnpActive,
    },
  ];
});
const DeveloperMenuItems = computed(() => {
  return [
    {
      title: "Overview",
      routes: "/developers",
      routeName: null,
      icon: overviewIcon,
      iconActive: overviewActive,
    },
    {
      title: "API Documentation",
      description: ``,
      routes: "/developers/documentation",
      routeName: null,
      icon: apiDocumentation,
      iconActive: apiDocumentationActive,
    },
    {
      title: "API Status",
      description: ``,
      routes: "/#",
      routeName: null,
      icon: apiDocumentation,
      iconActive: apiDocumentationActive,
    },
  ];
});
</script>

<style lang="scss" scoped>
.over {
  box-shadow: 0px 20px 48px 0px #aaaaaa4a;
}
.hamburger {
  display: grid;
  gap: 4px;
  cursor: pointer;

  span {
    display: inline-block;
    height: 2px;
    width: 20px;
    background-color: #14181f;
    border-radius: 2px;
  }
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 35px;
}

.nav-link {
  font-weight: 500;
  transition: 0.2s ease;
  cursor: pointer;

  &:hover {
    color: #ea4e4b;
  }

  &.router-link-active {
    color: #ea4e4b;
  }
}

.nav-btns {
  display: flex;
  align-items: center;
  gap: 20px;

  a {
    width: 108px;
    border-radius: 99px;
    padding: 0px;
    height: 40px;
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }

  .login-btn {
    // border: 1px solid #11453b;
    background: rgba(17, 69, 59, 0.1);
    color: #11453b;
    font-weight: 500;
  }
  .register-btn {
    // border: 1px solid #11453b;
    background: #11453b;
    color: white;
  }
}

.mobile-nav-menu {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  z-index: 20;
  width: 95vw;
  transform: translateX(100%);
  transition: 0.24s ease;
  visibility: hidden;

  &.open {
    transform: translateX(0);
    visibility: visible;
  }

  a {
    color: #515251;
    font-size: 16px;
    font-weight: bold;
  }

  a.btn-shadow {
    color: white;
  }
}

.mobile-nav-overlay {
  background: rgb(17, 69, 59, 0.5);
  opacity: 0;
  visibility: hidden;
  opacity: 0.25s ease;
  z-index: 10;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
</style>
