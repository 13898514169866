<template>
  <div class="">
    <!-- WARNING ////// -->
    <div v-if="userProfile && userProfile.membership == 'owner'">
      <div
        v-if="!isUserVerified && showWarning"
        class="bg-lightBorder w-full flex justify-between p-4 md:px-6"
      >
        <div class="flex gap-5">
          <img src="@/assets/icons/bx_error.svg?url" alt="" />

          <p
            class="text-white font-medium cursor-pointer"
            @click="$router.push('/developers/dashboard/profile?kyc=true')"
          >
            Kindly verify your account by clicking here
          </p>
        </div>
        <img
          src="@/assets/icons/CANCEL.png"
          class="w-6 h-6 cursor-pointer"
          alt=""
          @click="showWarning = false"
        />
      </div>
    </div>
    <!-- PAGE STARTS HERE //////// -->
    <div class="px-9">
      <p class="font-bold text-2xl text-black my-4">Overview</p>
      <div class="border border-outlineGray p-8">
        <div class="flex justify-between items-center">
          <!-- CONTRIBUTOR DROPDOWN //// -->
          <div class="relative">
            <div
              class="flex items-center gap-1 cursor-pointer"
              @click="showContributions = !showContributions"
            >
              <p class="font-medium text-xl text-black">All Contributions</p>
              <img
                src="@/assets/icons/chevron-down.png"
                class="w-5 h-5"
                alt=""
              />
            </div>

            <OnClickOutside @trigger="showContributions = false">
              <div
                v-show="showContributions"
                class="rounded-xl flex p-1 z-10 flex-col w-40 border-t border-dark-100 bg-white absolute left-0 md:left-20 mt-1 -right-4 top-8 py-2 shadow-lg text-sm"
              >
                <div class="px-1 py-2 flex flex-col gap-4">
                  <div
                    v-for="contributor in contributors"
                    :key="contributor._id"
                    @click="activeContributorId = contributor._id"
                    class="flex w-full justify-between px-4 py-2 hover:bg-newLimeGreen rounded-md cursor-pointer"
                  >
                    <p>{{ contributor.name }}</p>
                    <img
                      v-if="contributor._id == activeContributorId"
                      src="@/assets/icons/check.svg?url"
                      alt=""
                    />
                  </div>
                  <div v-if="!contributors.length" class="text-center text-sm">
                    No contributors
                  </div>
                </div>
              </div>
            </OnClickOutside>
          </div>
          <!-- LAST 7 DAYS DROPDOWN //// -->
          <div class="relative">
            <div
              class="flex items-center gap-1 cursor-pointer"
              @click="showTimeframe = !showTimeframe"
            >
              <p class="font-medium text-sm text-black">
                {{ activeTimeFrame }}
              </p>
              <img
                src="@/assets/icons/chevron-down.png"
                class="w-5 h-5"
                alt=""
              />
            </div>
            <OnClickOutside @trigger="showTimeframe = false">
              <div
                v-show="showTimeframe"
                class="rounded-xl flex p-1 z-10 flex-col w-40 border-t border-dark-100 bg-white absolute -left-8 md:-left-20 mt-1 -right-4 top-8 py-2 shadow-lg text-sm"
              >
                <div class="relative">
                  <div class="px-1 py-2 flex flex-col gap-4">
                    <div
                      v-for="(timeFrame, timeFrameIndex) in timeFrames"
                      :key="timeFrameIndex"
                      class="flex w-full justify-between px-4 py-2 hover:bg-newLimeGreen rounded-md cursor-pointer"
                      @click="handleTimeFrame(timeFrame)"
                    >
                      <div class="flex gap-4">
                        <p>{{ timeFrame.title }}</p>

                        <img
                          v-if="timeFrame.title === 'Customize'"
                          src="@/assets/icons/chevron-down.png"
                          class="w-5 h-5"
                          alt=""
                        />
                      </div>
                      <img
                        v-if="
                          timeFrame.title === activeTimeFrame &&
                          activeTimeFrame !== 'Customize'
                        "
                        src="@/assets/icons/check.svg?url"
                        alt=""
                      />
                    </div>
                  </div>

                  <!-- customise model /// -->

                  <div
                    @click="
                      showCustomizeModal = false;
                      showTimeframe = false;
                    "
                    :class="!showCustomizeModal ? 'pointer-events-none' : ''"
                    class="bg-transparent fixed inset-0"
                  ></div>
                  <div
                    v-show="showCustomizeModal"
                    class="rounded-xl flex p-4 z-10 flex-col w-90 bg-white absolute mt-1 right-0 -bottom-36 py-2 shadow-lg text-sm"
                  >
                    <div class="grid grid-cols-2 gap-4">
                      <div class="flex flex-col items-start">
                        <label for="startDate">From</label>
                        <input
                          v-model="customFilter.startDate"
                          class="focus:border-primary focus:ring-primary border-dark-200 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-2 px-4 block appearance-none rounded-2xl my-1"
                          type="month"
                          name="received"
                          id="received"
                        />
                      </div>
                      <div class="flex flex-col items-start">
                        <label for="startDate">To</label>
                        <input
                          v-model="customFilter.endDate"
                          class="focus:border-primary focus:ring-primary border-dark-200 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-2 px-4 block appearance-none rounded-2xl my-1"
                          type="month"
                          name="received"
                          id="received"
                        />
                      </div>
                    </div>
                    <hr class="my-4" />
                    <div class="grid grid-cols-2 text-center pb-2 font-medium">
                      <div
                        class="text-secondary cursor-pointer"
                        @click="
                          showCustomizeModal = false;
                          showTimeframe = false;
                        "
                      >
                        Cancel
                      </div>
                      <div class="cursor-pointer" @click="handleCustomFilter">
                        Done
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </OnClickOutside>
          </div>
        </div>

        <!-- CHART AND STATS//// -->
        <div class="mt-4 md:grid grid-cols-12 items-center gap-8">
          <div class="col-span-4">
            <ApiDashBoardChart />
          </div>

          <div
            class="border border-outlineGray rounded-2xl p-6 col-span-8 flex flex-col gap-6"
          >
            <div
              v-for="(conItem, i) in contributionsData"
              :key="`${conItem.title}-${i}`"
              class="bg-newGrey rounded-lg px-2 py-3 flex items-center justify-between text-sm"
            >
              <div class="flex gap-2 items-center">
                <div
                  :class="[conItem.bgColor]"
                  class="w-3 h-3 border-2 border-white rounded-full"
                ></div>

                <p class="text-sm">{{ conItem.title }}</p>
              </div>
              <div class="flex gap-2">
                <p>{{ conItem.amount }}</p>
                <p
                  :class="[conItem.textColor]"
                  class="border-l border-outlineGray pl-2"
                >
                  {{ conItem.percentage }}%
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-6" />
        <div class="flex justify-between font-bold">
          <p>Total Contribution</p>
          <p>
            {{ formatAmount(contributions && contributions.totalAmountPaid) }}
          </p>
        </div>
      </div>

      <!-- API CALLS //// -->
      <div class="border border-outlineGray p-8 mt-7">
        <p class="font-medium text-xl mb-6">
          API Calls ({{ apiCalls.length }})
        </p>
        <div class="grid grid-cols-10 gap-6 items-center">
          <div class="col-span-9">
            <ApiDashBoardSearch @update:modelValue="handleSearch" />
          </div>

          <div class="col-span-1">
            <ApiDashboardFilter
              @update="filterFN"
              ref="apiFilterRef"
              :companyProducts="contributorsNames"
            />
          </div>
        </div>
        <ApiActiveFilters @close="removeFilter" :filters="activeFilters" />

        <hr class="mt-6 mb-4" />
        <ApiDashboardTable
          :showAddNewButton="isAddProductButton"
          :data="apiCalls"
        />
        <TaxPagination
          v-if="apiCalls.length"
          :totalItems="totalItems"
          :pageSize="pageSize"
          :currentPage="currentPage"
          @page-change="handlePageChange"
        ></TaxPagination>
      </div>
    </div>

    <easiRoundLoader v-if="loading" />
  </div>
</template>

<script setup>
import { ref, reactive, computed, watch, onMounted } from "vue";
import ApiDashBoardChart from "@/components/ApiDashboard/ApiDashBoardChart.vue";
import ApiDashBoardSearch from "@/components/ApiDashboard/ApiDashBoardSearch.vue";
import ApiDashboardFilter from "@/components/ApiDashboard/ApiDashboardFilter.vue";
import ApiActiveFilters from "@/components/ApiDashboard/ApiActiveFilters.vue";
import ApiDashboardTable from "@/components/ApiDashboard/ApiDashboardTable.vue";
import TaxPagination from "@/components/TaxFiling/TaxPagination.vue";

import { OnClickOutside } from "@vueuse/components";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";

const toast = useToast();
const { formatAmount } = helperFunctions;
const store = useDataStore();
const { query, mutate } = store;

const loading = ref(true);

const showWarning = ref(true);
const showTimeframe = ref(false);
const showCustomizeModal = ref(false);
const showContributions = ref(false);
const apiFilterRef = ref(null);

const isUserVerified = computed(() => {
  return userProfile.value && userProfile.value.companyKycStatus == "verified";
});
function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero based
  const day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
const pageSize = ref(10);
const totalItems = ref(0);
const currentPage = ref(1);
const apiFilter = reactive({
  serviceType: null,
  product: null,
  from: "2023-01-01",
  to: getCurrentDate,
  alphabetic: null,
  amountPaid: null,
  skip: currentPage.value === 1 ? 0 : pageSize.value * (currentPage.value - 1),
  limit: pageSize.value,
  search: null,
});

watch(
  apiFilter,
  () => {
    queryApiCalls(true);
  },
  { deep: true }
);

const activeTimeFrame = ref("");
const timeFrames = ref([
  {
    title: "Last 7 days",
    value: 7,
  },
  {
    title: "Last 14 days",
    value: 14,
  },
  {
    title: "Customize",
    value: "Customize",
  },
]);
const customFilter = reactive({
  startDate: null,
  endDate: null,
});

const activeFilters = ref([]);
const filterOptions = ref({
  sort: { firstName: "ASC" },
  filter: [],
});

const companyData = computed(() => store.getCompanyAdmin);
const localApiCalls = ref([]);
const apiCalls = computed(() =>
  store.getAllApiCalls.length ? store.getAllApiCalls : localApiCalls.value
);
const userProfile = computed(() => store.apiProfile);
const getProductIds = (names) => {
  if (!names || !names.length) return null;

  let matchingIds = [];

  contributors.value.forEach((item) => {
    if (names.includes(item.name)) {
      matchingIds.push(item._id);
    }
  });

  return matchingIds;
};
const isAddProductButton = computed(() => {
  return (
    !contributors.value.length &&
    userProfile.value &&
    userProfile.value.permission.includes("ADD_PRODUCT")
  );
});

const activeContributorId = ref(null);
const contributors = computed(() => store.getAllproducts || []);
const contributorsNames = computed(() =>
  contributors.value.map((value) => value.name)
);
const contributions = computed(() => store.getContribution || null);
const contributionsData = computed(() => {
  const {
    totalAmountPaid,
    totalMicropensionAmount,
    totalPAYEAmount,
    totalPensionAmount,
  } = contributions.value || {};

  function getPercentage(amount) {
    return amount ? (amount * 100) / totalAmountPaid : 0;
  }

  const data = [
    {
      title: "PAYE",
      textColor: !!totalAmountPaid ? "text-deepBlue" : "text-gray-200",
      bgColor: !!totalAmountPaid ? "bg-deepBlue" : "bg-gray-200",
      amount: formatAmount(totalPAYEAmount),
      percentage: getPercentage(totalPAYEAmount),
    },
    {
      title: "Pension",
      textColor: !!totalAmountPaid ? "text-successStatus" : "text-gray-200",
      bgColor: !!totalAmountPaid ? "bg-successStatus" : "bg-gray-200",
      amount: formatAmount(totalPensionAmount),
      percentage: getPercentage(totalPensionAmount),
    },
    {
      title: "Micro Pension",
      textColor: !!totalAmountPaid ? "text-deepOrange" : "text-gray-200",
      bgColor: !!totalAmountPaid ? "bg-deepOrange" : "bg-gray-200",
      amount: formatAmount(totalMicropensionAmount),
      percentage: getPercentage(totalMicropensionAmount),
    },
  ];
  return data;
});
const contributionPayload = reactive({
  companyId: null,
  filterDays: 7,
  from: null,
  to: null,
  productId: activeContributorId.value,
});

watch(contributors, () => {
  setActiveContributorId();
});

watch(activeContributorId, async () => {
  contributionPayload.productId = activeContributorId.value;
});

watch(contributionPayload, async () => {
  try {
    loading.value = true;
    await queryContributions();
  } catch (error) {
  } finally {
    loading.value = false;
  }
});

onMounted(() => {
  initOverview();
});

//Methods

const handlePageChange = (page) => {
  currentPage.value = page;
  queryApiCalls();
};

const handleSearch = (e) => {
  apiFilter.search = e;
};

const removeFilter = (arg) => {
  if (apiFilterRef.value && apiFilterRef.value.removeFilter) {
    apiFilterRef.value.removeFilter(arg);
  }
};

const filterFN = async ({ filter, active }) => {
  filterOptions.value = filter;
  let valuesArray = [];
  let productArray = [];
  let serviceArray = [];
  let startDate = "";
  let endDate = "";
  let amountPaid = "";
  let alphabetic = "";
  active.forEach((obj) => {
    for (let key in obj) {
      if (key === "service") {
        serviceArray.push(obj[key]);
      } else if (key === "product") {
        productArray.push(obj[key]);
      } else if (key === "amountPaid") {
        amountPaid = obj[key];
      } else if (key === "alphabetic") {
        alphabetic = obj[key];
      } else if (key === "date") {
        startDate = obj[key];
      }
      valuesArray.push(obj[key]);
    }
  });
  apiFilter.alphabetic =
    alphabetic === "z-a" ? "ASC" : alphabetic === "a-z" ? "DESC" : null;
  apiFilter.serviceType = serviceArray.length
    ? serviceArray.map((each) => {
        if (each === "Micro Pension") return "MICRO_PENSION";
        else if (each === "Pension") return "PENSION";
        else if (each === "PAYE") return "PAYE";
      })
    : null;
  apiFilter.product = productArray.length ? getProductIds(productArray) : null;
  apiFilter.from = startDate ? startDate.split(" - ")[0] : null;
  apiFilter.to = startDate ? startDate.split(" - ")[1] : null;
  apiFilter.amountPaid =
    amountPaid === "lowest-highest"
      ? "ASC"
      : amountPaid === "highest-lowest"
      ? "DESC"
      : null;
  console.log("only", {
    allService: serviceArray,
    allproducts: productArray,
    amountPaid: amountPaid,
    alphabetic: alphabetic,
    startDate: startDate,
  });
  activeFilters.value = valuesArray;
};

const setActiveContributorId = () => {
  activeContributorId.value =
    activeContributorId.value ||
    (contributors.value.length && contributors.value[0]._id) ||
    null;
};

const handleTimeFrame = (timeframe) => {
  activeTimeFrame.value = timeframe.title;
  if (timeframe.title === "Customize") {
    showCustomizeModal.value = true;
  } else {
    contributionPayload.filterDays = timeframe.value;
  }
};

const handleCustomFilter = () => {
  if (!customFilter.startDate || !customFilter.endDate) {
    toast.error("Please select a date");
  } else {
    showCustomizeModal.value = false;
    showTimeframe.value = false;

    contributionPayload.from = customFilter.startDate;
    contributionPayload.to = customFilter.endDate;

    activeTimeFrame.value = `${customFilter.startDate} - ${customFilter.endDate}`;
    customFilter.startDate = "";
    customFilter.endDate = "";
  }
};

const initOverview = async () => {
  activeTimeFrame.value =
    activeTimeFrame.value || timeFrames.value[0].title || null;

  loading.value = true;

  try {
    await queryContributions();
    await queryProducts();
    setActiveContributorId();
    await queryApiCalls();
    await queryProfile();
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const queryContributions = async () => {
  try {
    const { company } = companyData.value;
    if (!company) return;
    contributionPayload.companyId = company._id;

    const res = await query({
      endpoint: "GetCompanyContributions",
      payload: {
        input: contributionPayload,
      },
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiContribution: res.data,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

const queryApiCalls = async (showLoader = false) => {
  try {
    const { company } = companyData.value;
    if (!company) return;
    if (showLoader) loading.value = true;

    const res = await query({
      endpoint: "GetAllCompanyCall",
      payload: {
        input: {
          companyId: company._id,
          serviceType: apiFilter.serviceType ? apiFilter.serviceType : null,
          product: apiFilter.product ? apiFilter.product : null,
          from: apiFilter.from ? apiFilter.from : null,
          to: apiFilter.to ? apiFilter.to : null,
          alphabetic: apiFilter.alphabetic ? apiFilter.alphabetic : null,
          amountPaid: apiFilter.amountPaid ? apiFilter.amountPaid : null,
          limit: apiFilter.limit ? apiFilter.limit : 0,
          skip: apiFilter.skip ? apiFilter.skip : 0,
          search: apiFilter.search ? apiFilter.search : null,
        },
      },
      service: "API",
    });

    if (res.success) {
      totalItems.value = res.data.callCount;
      localApiCalls.value = res.data.calls;
      store.$patch({
        apiCalls: res.data.calls,
      });
    }
  } catch (e) {
    console.log(e);
  } finally {
    if (showLoader) loading.value = false;
  }
};

const queryProducts = async () => {
  try {
    const { company } = companyData.value;
    if (!company) return;

    const res = await query({
      endpoint: "ListCompanyProducts",
      payload: {
        input: {
          companyId: company._id,
        },
      },
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiProducts: res.data,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

const queryProfile = async () => {
  try {
    const { companyAdmin } = companyData.value || {};
    if (!companyAdmin) return;

    const res = await query({
      endpoint: "GetEmployeeProfile",
      payload: {
        input: {
          employeeId: companyAdmin._id,
        },
      },
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiProfile: res.data,
      });
    }
  } catch (e) {
    console.log(e);
  }
};
</script>

<style lang="scss" scoped></style>
