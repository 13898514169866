<template>
  <div class="card">
    <!-- <Toast></Toast> -->
    <div class="flex flex-col w-full">
      <ProgressBar class="custom" :showValue="false" :value="value1" />
      <span class="w-full text-xs hidden mt-3 text-center text-grey"
        >{{ value1 >= 100 ? "Upload complete..." : "Uploading..." }}
        {{ value1 }}%</span
      >
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { ProgressBar } from "primevue";
// import { useToast } from "primevue/usetoast";
const props = defineProps({
  loadNum: {
    type: Number,
  },
});
onMounted(async () => {
  if (props.loadNum) {
    value1.value = props.loadNum;
  }
  await startProgress();
});

onUnmounted(() => {
  endProgress();
});

// const toast = useToast();
const value1 = ref(0);
const interval = ref(null);
const intervalNum = props.loadNum ? 500 : 800;
const added = props.loadNum ? 60 : 15;
const startProgress = async () => {
  interval.value = await setInterval(() => {
    let newValue = value1.value + added;
    if (newValue >= 100) {
      newValue = 100;
      clearInterval(interval.value);
      interval.value = null;
      //   toast.add({
      //     severity: "info",
      //     summary: "Success",
      //     detail: "Process Completed",
      //     life: 1000,
      //   });
    }
    value1.value = newValue;
  }, intervalNum);
};
const endProgress = () => {
  clearInterval(interval.value);
  interval.value = null;
};
</script>
<style scoped>
::v-deep(.custom .p-progressbar-value) {
  color: #219653 !important;
  background-color: #219653 !important;
  width: 100% !important;
  /* position: relative; */
}
.custom .p-progressbar-value {
  background-color: #219653 !important;
  width: 100% !important;
}
.p-progressbar {
  border-radius: 20px;
  height: 6px;
  width: 100%;
  margin: 0 auto;
}

:deep(.custom .p-progressbar-value) {
  background-color: #219653 !important;
  width: 100% !important;
  border-color: #219653 !important;
}
</style>
