<template>
  <div class="px-4 md:px-9 pt-8">
    <div
      class="flex gap-2 mb-10 font-medium cursor-pointer"
      @click="$router.push('/developers/dashboard/api-keys/product')"
    >
      <img src="@/assets/icons/arrow-small-left.svg?url" alt="" />
      Back to Eazipay
    </div>
    <div class="border border-outlineGray p-8 mt-7">
      <p class="font-bold text-xl mb-6">Transactions</p>
      <div class="grid grid-cols-10 gap-6 items-center">
        <div class="col-span-9">
          <ApiDashBoardSearch @update:modelValue="handleSearch" />
        </div>
        <div class="col-span-1">
          <ApiDashboardFilter @update="filterFN" ref="apiFilterRef" />
        </div>
      </div>
      <ApiActiveFilters @close="removeFilter" :filters="activeFilters" />

      <hr class="mt-6 mb-4" />
      <ApiDashboardTable />
      <TaxPagination
        :totalItems="10"
        :pageSize="3"
        :currentPage="2"
        @page-change="handlePageChange"
      ></TaxPagination>
    </div>
  </div>
</template>

<script setup>
import ApiDashboardTable from "@/components/ApiDashboard/ApiDashboardTable.vue";
import ApiDashBoardSearch from "@/components/ApiDashboard/ApiDashBoardSearch.vue";
import TaxPagination from "@/components/TaxFiling/TaxPagination.vue";
import ApiDashboardFilter from "@/components/ApiDashboard/ApiDashboardFilter.vue";
import ApiActiveFilters from "@/components/ApiDashboard/ApiActiveFilters.vue";

const handlePageChange = (page) => {
  console.log(page);
};
</script>

<style lang="scss" scoped></style>
