<template>
  <div class="flex items-center relative">
    <input
      autocomplete
      :class="
        error
          ? 'focus:border-error border-error'
          : 'focus:border-primary border-dark-200'
      "
      class="bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border border-r-0 p-4 block appearance-none rounded-tl-2xl rounded-bl-2xl"
      v-model="inputValue"
      v-bind="$attrs"
      @keypress.enter="searchFN(suggestedActivity)"
      @keyup="searchActivity"
      @input="(e) => $emit('update:modelValue', e.target.value)"
    />
    <!-- :value="modelValue" -->

    <button
      @click="searchFN(suggestedActivity)"
      style="border: 3px solid transparent"
      class="rounded-tr-2xl rounded-br-2xl px-6 sm:px-8 py-4 bg-mint focus:bg-mint focus:outline-none"
    >
      <img src="../../assets/icons/search.svg?url" alt="" />
    </button>

    <div
      style="max-height: 500px"
      v-if="inputValue.trim() && isSuggestion"
      class="overflow-y-auto w-full h-auto bg-white shadow-md z-2 mt-1 rounded-md absolute top-14 left-0 overflow-hidden"
    >
      <easiLoader v-if="loading" />

      <div
        v-for="(item, i) in suggestedActivity"
        :key="i"
        @click="selectSuggestion(item)"
        class="w-full last:border-b-0 border-b border-dark-50 p-2 sm:p-4 cursor-pointer hover:bg-background-50 flex gap-3 items-center"
      >
        <div
          v-if="item && item.creator && item.creator.pfp"
          class="border w-9 h-9 rounded-full overflow-hidden"
        >
          <img class="-full" :src="item.creator.pfp" :alt="item.name" />
        </div>
        <div
          v-else
          :style="`background-color:${getAvatar(item).color}`"
          class="p-2 h-9 w-9 text-sm text-white font-medium rounded-full"
        >
          {{ getAvatar(item).name }}
        </div>
        <div
          class="text-dark-800 text-md font-medium flex flex-col items-start"
        >
          <span>{{ item && item.name }}</span>
          <div class="text-sm text-dark-700 font-thin flex gap-2">
            <span class="font-medium text-left"> {{ item && item.type }}</span>
            <span v-if="isActivity" class="text-left break-words">{{
              item && item.activity
            }}</span>
            <span v-if="item.amount" class="font-medium text-primary min-w-max"
              >: {{ item && item.amount && formatAmount(item.amount) }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";

const store = useDataStore();
const { query } = store;
const { suggestedEmployees, checkLoading } = storeToRefs(store);
const { formatAmount, log } = helperFunctions;

const recentActivities = computed(() => store.getRecentActivities.data);
const activities = computed(() => {
  let arr = [];
  const obj = {
    Bonus: recentActivities.value.bonusActivities,
    "Employee Management": recentActivities.value.employeeActivities,
    Payroll: recentActivities.value.payrollActivities,
    Wallet: recentActivities.value.walletActivities,
    Deduction: recentActivities.value.deductionActivities,
  };

  Object.keys(obj).forEach((key) => {
    obj[key].forEach((el) => {
      el.type = key;
    });

    arr = [...arr, ...obj[key]];
  });
  return arr;
});

let suggestedActivity = ref([]);

const emit = defineEmits(["search", "update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },

  error: {
    type: [String, Boolean],
    default: null,
  },
});

const isActivity = ref(false);
const isSuggestion = ref(false);
const loading = ref(false);
const input = ref("");
const inputValue = computed(() => props.modelValue);

watch(inputValue, async (val) => {
  isSuggestion.value = true;
});

function getColor(index) {
  let n = (index * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}

const getAvatar = (employee) => {
  let name = "",
    color;

  if (employee) {
    let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
    const empId = employee.createdAt;
    const thenum = empId ? Number(empId.replace(/\D/g, "")) : 1;
    const initialOne = String(employee.name.split(" ")[0].charAt(0));
    const initialTwo = String(employee.name.split(" ")[1].charAt(0));
    const num =
      Number(alphabet.indexOf(initialOne.toLowerCase())) +
      Number(alphabet.indexOf(initialTwo.toLowerCase()));
    color = getColor(num);
    // Get color

    employee
      ? (name = `${employee.name.split(" ")[0].charAt(0)}${employee.name
          .split(" ")[1]
          .charAt(0)}`)
      : "";
  }
  return {
    color,
    name,
  };
};

const selectSuggestion = async (arg) => {
  inputValue.value = arg ? arg.name : null;
  await searchFN([arg]);
};

const searchFN = (arg) => {
  isSuggestion.value = false;
  emit("search", arg);
};

const searchActivity = async () => {
  if (inputValue.value.trim() !== "" && activities.value) {
    loading.value = true;
    const startWithNumber = /^\d/.test(inputValue.value);
    suggestedActivity.value = await activities.value.filter((el) => {
      if (startWithNumber && el.amount) {
        loading.value = false;
        const amount = inputValue.value
          .trim()
          .split(",")
          .join("")
          .split(".")
          .join("");
        return el.amount.toString().includes(amount);
      } else if (!startWithNumber) {
        isActivity.value = false;
        let result;
        loading.value = false;
        result = el.name.toLowerCase().includes(inputValue.value.toLowerCase());
        if (!result) {
          isActivity.value = true;
          result = el.activity
            .toLowerCase()
            .includes(inputValue.value.toLowerCase());
        }
        return result;
      }
    });
  } else {
    suggestedActivity.value = [];
  }
};

const querySuggestedEmployees = async () => {
  if (inputValue.value.trim() !== "") {
    const payload = {
      paging: {
        limit: 1000,
        skip: 0,
        search: inputValue.value,
        sort: {
          firstName: "ASC",
        },
      },
      filter: [],
    };
    try {
      loading.value = true;
      await query({
        endpoint: "ListEmployees",
        payload: payload,
        service: "EMP",
        storeKey: "suggestedEmployees",
      })
        .then(() => {
          loading.value = false;
        })
        .catch((err) => {
          loading.value = false;
        });
    } catch (err) {
      loading.value = false;
      console.log(err);
    }
  } else {
    store.$patch({
      suggestedEmployees: [],
    });
  }
};
</script>

<style></style>
