<template>
  <section class="hero h-auto lg:h-auto xl:h-screen relative md:pb-20">
    <div class="relative">
      <div
        :class="bool ? 'buz-gradient' : 'emp-gradient'"
        class="pt-8 md:px-auto relative hero-bg"
      >
        <div class="hero-container l-container md:w-full">
          <div
            class="absolute-center md:top-5 md:mt-10 py-2 mx-auto md:mx-0 justify-center cursor-pointer flex items-center gap-2 px-2 w-10/12 md:w-64 bg-white rounded-full shadow-lg"
          >
            <div
              @click="switchView"
              class="cursor-pointer"
              :class="
                bool
                  ? ' bg-primary-deep text-center rounded-full w-full py-3 px-3 text-sm md:text-sm font-medium text-mint'
                  : 'bg-white text-center text-dark-800 text-sm md:text-sm w-full '
              "
            >
              For Businesses
            </div>
            <div
              @click="switchView"
              class="cursor-pointer"
              :class="
                !bool
                  ? ' bg-primary-deep text-center rounded-full w-full py-3 px-3 text-sm md:text-sm font-medium text-mint'
                  : 'bg-white text-dark-800 text-center text-sm md:text-sm w-full '
              "
            >
              For Employees
            </div>
          </div>

          <div class="hero-content">
            <div
              class="hidden text-center flex-col items-center md:mt-40 md:block lg:text-left"
            >
              <div
                v-if="bool"
                @click="$router.push({ name: 'LearnMore' })"
                class="bg-white cursor-pointer p-2 text-xs w-10/12"
              >
                We see you in these difficult times, we are here to help 🤗
                <span class="text-secondary font-medium ml-2"
                  >Learn more ></span
                >
              </div>
              <div
                v-else
                @click="$router.push({ name: 'LearnMore' })"
                class="bg-white cursor-pointer p-2 text-xs w-10/12"
              >
                The one-stop app for employees & income earners
                <span class="text-secondary font-medium ml-2"
                  >Learn more ></span
                >
              </div>
              <transition v-if="bool" name="slider" mode="out-in">
                <h1
                  v-if="i == 1"
                  style="font-size: 40px; line-height: 56px"
                  class="slider relative font-bold level-1 mt-4"
                >
                  Stand a chance <br />
                  to get up to
                  <span class="absolute -mt-2"
                    ><img class="w-28" src="./assets/red-banner.png" alt=""
                  /></span>
                  <br />Payroll relief for <br />your Employees
                </h1>

                <h1
                  v-else-if="i == 2"
                  style="font-size: 40px; line-height: 56px"
                  class="slider relative font-bold level-1 mt-4"
                >
                  File Your Taxes <br />
                  and Annual PAYE <br />
                  at Zero Cost <br />with Eazipay
                  <!-- Save BIG! Get amazing Cash backs on your <br />

                  <span style="font-size: 44px; line-height: 52px" class="text-primary font-bold"
                    >Annual PAYE <br />
                    filing fees</span
                  > -->
                </h1>

                <h1
                  v-else
                  style="font-size: 40px; line-height: 56px"
                  class="slider relative font-bold level-1 mt-4"
                >
                  Enjoy zero interest <br />
                  <span class="text-secondary">Loans & Micro-Loans</span> <br />
                  When you sign up <br />
                  with Eazipay
                </h1>
              </transition>

              <transition v-else name="slider" mode="out-in">
                <h1
                  v-if="k == 1"
                  style="font-size: 40px; line-height: 56px"
                  class="slider relative font-bold level-1 mt-4"
                >
                  Stand a chance <br />
                  to get up to
                  <span class="absolute -mt-2"
                    ><img class="w-28" src="./assets/red-banner.png" alt=""
                  /></span>
                  <br />
                  Extra salaries
                </h1>

                <!-- <h1
                  v-else-if="k == 2"
                  style="font-size:40px;line-height: 56px"
                  class="slider  relative font-bold level-1 mt-4"
                >
                  Get low interest loans and <br />
                  Micro loans when you <br />
                  sign up on WorknProsper
                </h1> -->

                <h1
                  v-else-if="k === 2"
                  style="font-size: 40px; line-height: 56px"
                  class="slider relative font-bold level-1 mt-4"
                >
                  <img src="./assets/exclusive.png" class="w-44" alt="" />
                  Get up to 100% coverage <br />
                  on your next House Rent
                </h1>

                <h1
                  v-else
                  style="font-size: 40px; line-height: 56px"
                  class="slider relative font-bold level-1 mt-4"
                >
                  We're buying lunch for <br />
                  500+ employees. Let’s <br />
                  buy yours next
                </h1>
              </transition>

              <!-- <p class="text-summary mb-6">
                Eazipay lets you easily manage your employees’ payroll, benefits, expenses, devices, apps, and
                assessment for prospective employees & more – all in one place.
              </p> -->
              <form
                @submit.prevent="submitMail"
                class="mt-10 lg:block md:hidden"
                action=""
              >
                <div
                  class="flex items-center justify-between py-1 rounded-l-xl rounded-r-3xl px-2 border w-10/12 bg-white border-dark-300"
                >
                  <input
                    type="email"
                    id="input"
                    required
                    v-model="email"
                    class="border-none border-white focus:outline-white w-9/12 focus:border-white focus:ring-none"
                    placeholder="Enter your email address"
                  />

                  <button
                    v-if="bool"
                    class="focus:outline-none flex justify-center text-xs px-2 hover:text-white text-primary rounded-l-lg rounded-r-3xl font-medium w-24 right-24 py-3"
                    :class="loading ? 'bg-primary' : 'btn-style'"
                  >
                    <div v-if="loading" class="loader"></div>

                    <span v-else> See Eazipay</span>
                  </button>
                  <button
                    v-else
                    style="background-color: #fff0ef"
                    class="focus:outline-none flex justify-center text-xs px-2 text-secondary rounded-l-lg ml-2 rounded-r-3xl w-52 font-medium right-24 py-3"
                  >
                    <div v-if="loading" class="loader"></div>

                    <span v-else>Explore WorknProsper</span>
                  </button>
                </div>
              </form>

              <!-- <div class="flex items-center gap-4">
                <SiteButton shadow to="/login"> Try Eazipay for free </SiteButton>
                <AppDownloadLinks />
              </div> -->

              <!-- <div class="mt-8 lg:mt-12">
                <p class="mb-4 text-black font-medium">
                  Download the Eazipay App
                </p>
              </div> -->
            </div>
          </div>
        </div>

        <div class="md:hidden w-full mt-7">
          <div
            v-show="bool"
            @click="$router.push({ name: 'LearnMore' })"
            class="md:hiddden bg-white cursor-pointer p-2 text-xs w-10/12 mx-auto"
          >
            We see you in these difficult times, we are here to help 🤗
            <span class="text-secondary font-medium ml-2">Learn more ></span>
          </div>
          <div
            v-show="!bool"
            @click="$router.push({ name: 'LearnMore' })"
            class="md:hiddden bg-white cursor-pointer p-2 text-xs w-10/12 mx-auto"
          >
            The one-stop app for employees & income earners
            <span class="text-secondary font-medium ml-2">Learn more ></span>
          </div>
        </div>

        <transition v-if="bool" name="slider" mode="out-in">
          <img
            v-if="i == 1"
            fetchpriority="high"
            class="md:absolute just-center mx-auto md:mx-auto mt-8 md:mt-10 md:right-32 md:block w-11/12 md:w-1/3 md:mt-72"
            src="./assets/hero-img-1.webp"
            alt=""
          />

          <img
            v-else-if="i == 2"
            fetchpriority="high"
            class="md:absolute just-center mx-auto md:mx-auto mt-8 md:mt-10 md:right-32 md:block w-11/12 md:w-1/3 md:mt-72"
            src="./assets/hero-img-2.webp"
            alt=""
          />
          <img
            v-else
            fetchpriority="high"
            class="md:absolute just-center mx-auto md:mx-auto mt-8 md:mt-10 md:right-32 md:block w-11/12 md:w-1/3 md:mt-72"
            src="./assets/hero-img-3.webp"
            alt=""
          />
        </transition>

        <transition v-else name="slider" mode="out-in">
          <img
            v-if="k == 1"
            fetchpriority="high"
            class="md:absolute just-center mx-automd:mx-auto mt-8 md:mt-10 md:right-32 md:block w-11/12 md:w-1/3 md:mt-72"
            src="./assets/hero-img-4.webp"
            alt=""
          />

          <!-- <img
            v-else-if="k == 2"
            fetchpriority="high"
            class="amd:bsolute  just-centermx-aautomd:mx-auto mt-8 md:mt-10 md:right-32 md:block w-11/12  m lg:w-1/3 md:mt-72"
            src="./assets/hero-img-5.webp"
            alt=""
          /> -->
          <img
            v-else-if="k === 2"
            fetchpriority="high"
            class="md:absolute just-center mx-auto md:mx-auto mt-8 md:mt-10 md:right-32 md:block w-11/12 md:w-1/3 md:mt-72"
            src="./assets/hero-img-6.webp"
            alt=""
          />

          <img
            v-else
            fetchpriority="high"
            class="md:absolute just-center mx-auto md:mx-auto mt-8 md:mt-10 md:right-32 md:block w-11/12 md:w-1/3 md:mt-72"
            src="./assets/hero-img-7.webp"
            alt=""
          />
        </transition>

        <div
          class="md:hidden text-center flex flex-col items-center md:mt-20 mt-72 lg:text-left"
        >
          <transition v-if="bool" name="slider" mode="out-in">
            <h1
              v-if="i == 1"
              class="slider text-2xl md:text-center relative font-bold mt-4"
            >
              Stand a chance to<br class="md:hidden" />
              get up to
              <img
                class="w-1/4 inline-block"
                src="./assets/red-banner.png"
                alt=""
              />
              <br />Payroll relief for <br class="md:hidden" />your Employees
            </h1>

            <h1
              v-else-if="i == 2"
              class="slider text-2xl relative font-bold mt-4"
            >
              <!-- Get tax relief or <br class="md:hidden" />
              cash backs on your <br class="md:hidden" /> -->

              File Your Taxes <br class="md:hidden" />
              and Annual PAYE <br class="md:hidden" />at Zero Cost
              <br class="md:hidden" />
              <span class="text-primary">with Eazipay</span>

              <!-- Save BIG! Get amazing <br class="md:hidden" />
              Cash backs on your <br />
              <img class="mx-auto mt-2 w-5/12" src="./assets/annual-paye-mobile.png" alt="" /> -->

              <!-- <span class="outline-text font-bold"
                >Annual PAYE <br class="md:hidden"/>
                filing fee</span
              > -->
            </h1>

            <h1 v-else class="slider text-2xl relative font-bold mt-4">
              Enjoy zero interest <br class="md:hidden" />
              <span class="text-secondary">Loans & Micro-Loans</span>
              <br class="md:hidden" />
              When you sign up <br class="md:hidden" />
              with Eazipay
            </h1>
          </transition>

          <transition v-else name="slider" mode="out-in">
            <h1
              v-if="k == 1"
              class="slider text-2xl relative font-bold md:mt-3 mt-4"
            >
              Stand a chance <br class="md:hidden" />
              to get up to
              <img
                class="w-1/4 inline-block"
                src="./assets/red-banner.png"
                alt=""
              />
              <br class="md:hidden" />
              Extra salaries
            </h1>

            <!-- <h1 v-else-if="k == 2" class="slider text-2xl relative font-bold md:mt-3 mt-4">
              Get low interest loans and <br class="md:hidden"/>
              Micro loans when you <br class="md:hidden"/>
              sign up on WorknProsper
            </h1> -->

            <h1
              v-else-if="k === 2"
              class="slider text-2xl relative font-bold md:mt-3 mt-4"
            >
              <img src="./assets/exclusive.png" class="w-44" alt="" />
              Get up to 100% coverage <br class="md:hidden" />
              on your next House Rent
            </h1>

            <h1 v-else class="slider text-2xl relative font-bold md:mt-3 mt-4">
              We're buying lunch for <br class="md:hidden" />
              500+ employees. Let’s <br class="md:hidden" />
              buy yours next
            </h1>
          </transition>

          <div
            v-if="bool"
            class="md:hidden flex items-center rounded-xl px-3 py-3 mt-3 mb-4 text-primary-deep font-medium"
          >
            <div
              v-for="box in 3"
              :key="box"
              @click="switchSlide(box)"
              :class="box === i ? 'border border-secondary' : 'border-none'"
              class="w-8 bg-none flex items-center justify-center h-8 rounded-full p-2"
            >
              <div
                :class="box === i ? 'bg-secondary' : ' bg-newLightGray'"
                class="w-2 h-2 rounded-full"
              ></div>
            </div>
          </div>

          <div
            v-else
            class="md:hidden flex items-center rounded-xl px-3 py-3 mt-3 mb-4 text-primary-deep font-medium"
          >
            <div
              v-for="box in 3"
              :key="box"
              @click="switchSlide(box)"
              :class="box === k ? 'border border-secondary' : 'border-none'"
              class="w-8 bg-none flex items-center justify-center h-8 rounded-full p-2"
            >
              <div
                :class="box === k ? 'bg-secondary' : ' bg-newLightGray'"
                class="w-2 h-2 rounded-full"
              ></div>
            </div>
          </div>

          <!-- <p class="text-summary mb-6">
                Eazipay lets you easily manage your employees’ payroll, benefits, expenses, devices, apps, and
                assessment for prospective employees & more – all in one place.
              </p> -->
          <form
            @submit.prevent="submitMail"
            class="w-full lg:block md:hidden"
            action=""
          >
            <div class="flex items-center justify-between">
              <input
                type="email"
                id="input"
                required
                v-model="email"
                class="focus:outline-white w-11/12 focus:border-white focus:ring-none py-4 rounded-3xl px-3 border mx-auto w-11/12 bg-white border-dark-300"
                placeholder="Enter your email address"
              />
            </div>

            <button
              v-if="bool"
              class="focus:outline-none flex justify-center items-center self-center mx-auto text-sm px-2 bg-primary text-white rounded-3xl font-medium w-11/12 mt-4 right-24 py-4"
            >
              <div v-if="loading" class="loader"></div>
              <span v-else> See Eazipay</span>
            </button>

            <button
              v-else
              class="focus:outline-none flex justify-center ml-4 text-sm px-2 bg-secondary text-white ml-2 rounded-3xl w-11/12 mt-4 font-medium right-24 py-4"
            >
              <div v-if="loading" class="loader"></div>
              <span v-else>Explore WorknProsper</span>
            </button>
          </form>

          <!-- <div class="flex items-center gap-4">
                <SiteButton shadow to="/login"> Try Eazipay for free </SiteButton>
                <AppDownloadLinks />
              </div> -->

          <!-- <div class="mt-8 lg:mt-12">
                <p class="mb-4 text-black font-medium">
                  Download the Eazipay App
                </p>
              </div> -->
        </div>
      </div>

      <div
        v-if="bool"
        class="hidden md:flex items-center bg-white rounded-xl px-3 py-3 mt-16 ml-24 text-primary-deep font-medium absolute ml-12"
      >
        <div
          v-for="box in 3"
          :key="box"
          @click="switchSlide(box)"
          :class="box === i ? 'border border-secondary' : 'border-none'"
          class="w-8 bg-none flex items-center justify-center cursor-pointer h-8 rounded-full p-2"
        >
          <div
            :class="box === i ? 'bg-secondary' : ' bg-newLightGray'"
            class="w-2 h-2 rounded-full"
          ></div>
        </div>
      </div>

      <div
        v-else
        class="hidden md:flex items-center bg-white rounded-xl px-3 py-3 mt-16 ml-24 text-primary-deep font-medium absolute ml-12"
      >
        <div
          v-for="box in 3"
          :key="box"
          @click="switchSlide(box)"
          :class="box === k ? 'border border-secondary' : 'border-none'"
          class="w-8 bg-none flex items-center justify-center cursor-pointer h-8 rounded-full p-2"
        >
          <div
            :class="box === k ? 'bg-secondary' : ' bg-newLightGray'"
            class="w-2 h-2 rounded-full"
          ></div>
        </div>
      </div>

      <div
        class="flex items-center gap-4 bg-white rounded-xl px-3 py-3 md:mb-0 md:h-auto mt-10 md:mt-16 md:w-64 ml-24 text-xs text-primary-deep font-medium md:absolute md:inset-x-1/2"
      >
        <img class="md:w-16 w-20" src="./assets/fg-badge.jpg" alt="" />

        <span>
          We are <span class="text-primary text-xs font-bold">NDPR</span> <br />
          Compliant</span
        >
      </div>
    </div>
  </section>
</template>

<script setup>
import AppDownloadLinks from "./AppDownloadLinks.vue";
import SiteButton from "../../components/SiteButton.vue";
import { ref } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";

const router = useRouter();

const toast = useToast();

const store = useDataStore();
const { mutate } = store;

const email = ref("");
const bool = ref(true);
const loading = ref(false);

const submitMail = async () => {
  loading.value = true;
  try {
    const res = await mutate({
      endpoint: "SubmitEmailForm",
      data: {
        email: email.value,
      },
      service: "SETTINGS",
    });

    if (res.success) {
      if (bool.value) {
        router.push({ name: "Register" });
      } else {
        window.open("https://worknprosper.com", "_blank");
      }
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    toast.error(error.message);
  } finally {
    loading.value = false;
  }
};

const i = ref(1);
const k = ref(1);
let buzInterval = setInterval(() => {
  i.value = i.value == 1 ? 2 : i.value == 2 ? 3 : 1;
}, 5000);

let empInterval = setInterval(() => {
  // k.value = k.value == 1 ? 2 : k.value == 2 ? 3 : k.value === 3 ? 4 : 1;
  k.value = k.value == 1 ? 2 : k.value == 2 ? 3 : 1;
}, 5000);

function switchView() {
  bool.value = !bool.value;
  clearInterval(buzInterval);
  clearInterval(empInterval);
  if (bool.value) {
    buzInterval = setInterval(() => {
      i.value = i.value == 1 ? 2 : i.value == 2 ? 3 : 1;
    }, 5000);
  } else {
    empInterval = setInterval(() => {
      // k.value = k.value == 1 ? 2 : k.value == 2 ? 3 : k.value === 3 ? 4 : 1;  for 4 items
      k.value = k.value == 1 ? 2 : k.value == 2 ? 3 : 1;
    }, 5000);
  }
}

function switchSlide(num) {
  clearInterval(buzInterval);
  clearInterval(empInterval);
  if (bool.value) {
    i.value = num;
    buzInterval = setInterval(() => {
      i.value = i.value == 1 ? 2 : i.value == 2 ? 3 : 1;
    }, 5000);
  } else {
    k.value = num;
    empInterval = setInterval(() => {
      // k.value = k.value == 1 ? 2 : k.value == 2 ? 3 : k.value === 3 ? 4 : 1; for 4 items

      k.value = k.value == 1 ? 2 : k.value == 2 ? 3 : 1;
    }, 5000);
  }
}
</script>

<style lang="scss" scoped>
/* HTML: <div class="loader"></div> */
.loader {
  width: 10px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ffffff;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
.btn-style {
  background-color: rgba(17, 69, 59, 0.1);
}
.btn-style:hover {
  background-color: #11453b;
}
// .slider {
//   animation: myAnim 2s ease 0s 1 normal forwards;
// }

// @keyframes myAnim {
//   0% {
//     opacity: 1;
//     transform: scale(1);
//   }

//   100% {
//     opacity: 0;
//     transform: scale(0.6);
//   }
// }
// .slider-enter-active {
//   animation: scale-in 1s forwards;
// }

// .slider-leave-active {
//   animation: scale-out 1s forwards;
// }

// @keyframes scale-in {
//   from {
//     transform: scale(0);
//   }
//   to {
//     transform: scale(1);
//   }
// }

// @keyframes scale-out {
//   from {
//     transform: scale(1);
//   }
//   to {
//     transform: scale(0);
//   }
// }

.slider-enter-active {
  animation-name: customAnimation;

  animation-timing-function: cubic-bezier(0.71, -0.31, 0.22, 1);
  animation-duration: 0.2s;
  animation-timing-function: ease;
}

.slider-leave-active {
  animation-name: customAnimation-out;
  animation-timing-function: cubic-bezier(1, 0.22, -0.31, 0.71);
  animation-duration: 0.2s;
  animation-delay: 0ms;
  animation-timing-function: ease;
}
@keyframes customAnimation {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
  // 0% {
  //   opacity: 0;
  //   transform: translate(100%, -100%) scale(0.8);
  // }
  // 100% {
  //   opacity: 1;
  //   transform: translate(0, 0) scale(1);
  // }

  // 0% {
  //   opacity: 0;
  //   transform: translate(-100%, -100%) scale(0.8);
  // }
  // 50% {
  //   opacity: 0.5;
  // }
  // 100% {
  //   opacity: 1;
  //   transform: translate(0, 0) scale(1);
  // }
}

@keyframes customAnimation-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.2);
    opacity: 0.4;
  }
  // 0% {
  //   opacity: 1;
  //   transform: translate(0, 0) scale(1);
  // }
  // 100% {
  //   opacity: 0;
  //   transform: translate(100%, 100%) scale(0.8);
  // }

  // 0% {
  //   opacity: 1;
  //   transform: translate(0, 0) scale(1);
  // }
  // 50% {
  //   opacity: 0.5;
  // }
  // 100% {
  //   opacity: 0;
  //   transform: translate(100%, 100%) scale(0.8);
  // }
}

// .element {
//   animation: customAnimation 1s cubic-bezier(0.71, -0.31, 0.22, 1) 5000ms forwards;
// }
@keyframes scale-in {
  0% {
    transform: scale(0.8);
    // opacity: 0;
  }
  50% {
    transform: scale(1);
    // opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.1);
    // opacity: 0;
  }
}
.hero {
  .emp-gradient {
    background-image: linear-gradient(180deg, #fff0ef, white);
  }
  .buz-gradient {
    background-image: linear-gradient(180deg, #e7f4df, white);
  }
  .hero-media-mobile {
    display: grid;
    place-items: center;
    margin-bottom: 32px;

    img {
      height: 100%;
      width: auto;
      max-height: 300px;
    }
  }

  .hero-content {
    max-width: 400px;
    margin: auto;
  }

  .hero-cta {
    height: 48px;
    font-weight: 500;
    transition: 0.3s ease;

    &:hover {
      transform: translateY(-1px);
    }
  }

  h1 {
    color: #292a29;
    margin-bottom: 24px;

    .payroll {
      color: #11453b;
    }

    .easily {
      color: #b4a572;
    }

    .seconds {
      color: #ea4e4b;
    }
  }

  @media (max-width: 576px) {
    .just-center {
      position: absolute;
      display: flex;
      width: 70%;
      justify-content: center;
      margin-left: 15%;
      // transform: translate(-50%, -50%);

      // top: 50%;
    }

    .smallscr {
      margin-top: 18rem;
    }
  }
  @screen lg {
    .absolute-center {
      position: absolute;
      // top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .hero-bg {
      height: 520px;
      padding: 0px 0 40px 0;

      // background-image: url(./assets/hero-media-desktop.png);
      background-position: right;
      background-repeat: no-repeat;
      background-size: auto 100%;
      display: flex;
      align-items: center;
    }
    #input {
      background-color: white;
      border: 2px solid #ffffff !important;
      border-radius: 18px !important;
    }
    #input:focus #input:active {
      outline: none !important;
      border-color: white !important;
    }
    .emp-gradient {
      background-image: linear-gradient(180deg, #fff0ef, white);
    }
    .buz-gradient {
      background-image: linear-gradient(180deg, #e7f4df, white);
    }
    .hero-container {
      width: 100%;
    }

    .hero-content {
      max-width: 500px;
      margin: 0;
    }

    .hero-media-mobile {
      display: none;
    }

    .outline-text {
      color: #11453b;
      text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      // -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #ffffff;
      font-size: 44px;
      font-style: normal;
      font-weight: 900;
      line-height: 52px;
    }
  }
}
</style>
