<template>
  <div
    class="flex items-center relative overflow-hidden border border-outlineGray rounded-xl"
  >
    <button class="pl-5 py-4 bg-white">
      <img src="../../assets/icons/apiSearch.svg?url" alt="" />
    </button>
    <input
      autocomplete
      class="bg-white border-none w-full text-info-700 font-medium text-sm leading-2 focus:outline-none p-4 block appearance-none rounded-tl-2xl rounded-bl-2xl"
      v-model="inputValue"
      v-bind="$attrs"
      @keypress.enter="searchFN"
      @keyup="querySuggestedEmployees"
      @input="
        (e) => {
          handleInput(e.target.value);
        }
      "
      :placeholder="placeholder"
    />
    <!-- :value="modelValue" -->
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { computed, ref, watch } from "vue";
import { useDataStore } from "@/stores/data.js";
const store = useDataStore();
const { query } = store;
const emit = defineEmits(["search", "update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "Search product name, service type or date",
  },
  error: {
    type: [String, Boolean],
    default: null,
  },
});

const isSuggestion = ref(false);
const loading = ref(false);
const input = ref("");
const inputValue = computed(() => props.modelValue);

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
const delayedSearch = debounce((query) => {
  emit("update:modelValue", query);
}, 500); // Set the debounce delay, e.g., 300 milliseconds
const handleInput = (e) => {
  delayedSearch(e);
};
watch(inputValue, async (val) => {
  isSuggestion.value = true;
});

const searchFN = () => {
  console.log(inputValue.value);
  emit("search", inputValue.value);
};

const querySuggestedEmployees = async () => {
  if (inputValue.value.trim() !== "") {
    const payload = {
      paging: {
        limit: 1000,
        skip: 0,
        search: inputValue.value,
        sort: {
          firstName: "ASC",
        },
      },
      filter: [],
    };
    try {
      loading.value = true;
      await query({
        endpoint: "ListEmployees",
        payload: payload,
        service: "EMP",
        storeKey: "suggestedEmployees",
      })
        .then(() => {
          loading.value = false;
        })
        .catch((err) => {
          loading.value = false;
        });
    } catch (err) {
      loading.value = false;
      console.log(err);
    }
  } else {
    store.$patch({
      suggestedEmployees: [],
    });
  }
};
</script>

<style></style>
