<template>
  <main>
    <div class="relative">
      <div
        @click="toggleFilter = true"
        class="inline border border-dark-300 rounded-2xl px-4 cursor-pointer flex items-center gap-2 bg-white w-fit"
        :class="[
          bonus ? 'rounded-l-none' : '',
          hideActiveFilter ? 'py-4' : 'py-3 ',
        ]"
      >
        <img
          v-show="!bonus"
          class="w-6 md:w-4"
          src="@/assets/icons/filter.svg?url"
          alt=""
        />
        <span class="text-dark-600 text-sm hidden md:flex items-center gap-3"
          >Filter <i v-show="bonus" class="fas fa-angle-right"></i
        ></span>

        <span
          :class="hideActiveFilter ? 'hidden' : undefined"
          class="ml-1 text-xs md:text-base font-medium md:flex"
        >
          {{ activeFilter }}
        </span>
      </div>

      <div
        v-if="toggleFilter"
        class="absolute h-auto w-52 right-0 shadow-lg bg-white flex flex-col text-sm py-2"
        :class="dropdownStyle2"
        style="z-index: 2"
      >
        <div
          v-for="option in sortOptions"
          :key="option.name"
          class="flex items-center gap-3 cursor-pointer px-4 py-2 hover:bg-mint"
          @click="filterAction(option)"
        >
          <span>
            <img
              v-if="activeFilter === option.name"
              class="w-4"
              src="../../assets/icons/check3-active.svg?url"
              alt=""
            />
            <img
              v-else
              class="w-4"
              src="../../assets/icons/check3-null.svg?url"
              alt=""
            />
          </span>
          <span>{{ option.name }}</span>
        </div>

        <div
          v-for="item in filterOptions"
          :key="item.name"
          @mouseover="item.active = true"
          @mouseout="item.active = false"
          class="relative group flex px-4 py-2 cursor-pointer hover:bg-mint"
        >
          {{ item.name }}
          <div
            v-show="item.active"
            :class="dropdownStyle"
            class="w-48 group-hover:text-dark-800 absolute -left-48 flex flex-col rounded-b-xl bg-white shadow-lg"
          >
            <div class="flex flex-col gap-2 pt-4">
              <div class="flex flex-col items-start px-4">
                <label for="startDate">Start Date</label>
                <input
                  v-model="transactionArgs.startDate"
                  class="focus:border-primary focus:ring-primary border-dark-200 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-2 px-4 block appearance-none rounded-2xl my-1"
                  type="date"
                  name=""
                  id=""
                />
              </div>

              <div class="flex flex-col items-start px-4">
                <label for="endDate">End Date</label>
                <input
                  v-model="transactionArgs.endDate"
                  class="focus:border-primary focus:ring-primary border-dark-200 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-2 px-4 block appearance-none rounded-2xl my-1"
                  type="date"
                  name=""
                  id=""
                />
              </div>
              <button
                @click="filterAction({ value: '', name: 'Custom' })"
                class="text-white text-sm font-medium focus:outline-none bg-primary rounded-b-xl w-full p-2 mt-2"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      @click="toggleFilter = false"
      :class="!toggleFilter ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>

    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script>
import { ref, reactive, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";

const store = useDataStore();
const { query } = store;
const { checkLoading } = storeToRefs(store);

export default {
  props: {
    bonus: {
      type: Boolean,
      default: false,
    },
    hideActiveFilter: {
      type: Boolean,
      default: false,
    },
  },

  emit: ["update"],

  data() {
    return {
      dropdownStyle: "top-0",
      dropdownStyle2: "top-16",
    };
  },
  setup(props, { emit }) {
    const companyData = computed(() => store.getCompanyAdmin);

    const activeFilter = ref("");
    const loading = ref(false);

    const toggleFilter = ref(false);
    const sortOptions = reactive([
      {
        name: "All",
        value: "all",
        active: true,
      },
      {
        name: "Last Month",
        value: 1,
        active: false,
      },
      {
        name: "3 Months",
        value: 3,
        active: false,
      },

      {
        name: "6 Months",
        value: 6,
        active: false,
      },
      {
        name: "1 Year",
        value: 12,
        active: false,
      },
    ]);

    const filterOptions = reactive([
      {
        name: "Custom date",
        active: false,
        value: "duration",
      },
    ]);

    // Functions

    const transactionArgs = ref({
      startDate: "",
      endDate: "",
      // companyId: "",
    });

    const queryTransactions = async () => {
      loading.value = true;
      try {
        await query({
          endpoint: "ViewInflowOutflowBalance",
          payload: {
            input: transactionArgs.value,
          },
          service: "PAYROLL",
          storeKey: "transactionHistory",
        });

        loading.value = false;
      } catch (err) {
        loading.value = false;
        console.log(err);
      }
    };

    const filterAction = (arg, remove = false) => {
      activeFilter.value = arg.name;
      if (arg.name !== "Custom") {
        if (arg.name === "All") {
          transactionArgs.value.startDate = "";
          transactionArgs.value.endDate = "";
          queryTransactions();
        } else {
          const d = new Date();
          // transactionArgs.value.endDate = d.toLocaleDateString();
          transactionArgs.value.endDate = `${
            d.getMonth() + 1
          }/${d.getDate()}/${d.getFullYear()}`;
          const month = d.getMonth();
          d.setMonth(d.getMonth() - arg.value);
          while (d.getMonth() === month) {
            d.setDate(d.getDate() - arg.value);
          }
          // transactionArgs.value.startDate = d.toLocaleDateString();
          transactionArgs.value.startDate = `${
            d.getMonth() + 1
          }/${d.getDate()}/${d.getFullYear()}`;

          queryTransactions();
        }
        // emit("update", transactionArgs.value);
      } else {
        if (transactionArgs.value.startDate && transactionArgs.value.endDate) {
          // emit("update", transactionArgs.value);
        }
      }

      emit("update", {
        filter: transactionArgs.value,
        active: remove ? [] : arg.name !== "All" ? [arg.name] : [],
      });
    };

    const removeFilter = (arg) => {
      filterAction(sortOptions[0], true);
      sortOptions.forEach((el) => {
        el.active = false;
      });
      activeFilter.value = "";
    };

    return {
      activeFilter,
      toggleFilter,
      sortOptions,
      filterOptions,
      filterAction,
      removeFilter,
      transactionArgs,
      loading,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.checkDropdownPosition);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkDropdownPosition);
  },

  methods: {
    checkDropdownPosition() {
      let dropdown = this.$el;
      let windowHeight = window.innerHeight;
      let dropdownRect = dropdown.getBoundingClientRect();
      let dropdownHeight = dropdownRect.height;
      let dropdownTop = dropdownRect.top;
      let dropdownBottom = dropdownRect.bottom;

      if (dropdownTop < 0) {
        this.dropdownStyle = "top-0";
      } else if (dropdownBottom > 300 && dropdownBottom < 400) {
        this.dropdownStyle = "-bottom-2";
        this.dropdownStyle2 = "top-16";
      } else if (dropdownBottom >= 400) {
        this.dropdownStyle = "top-0";
        this.dropdownStyle2 = "bottom-16";
      } else {
        this.dropdownStyle2 = "top-16";
        this.dropdownStyle = "top-0";
      }
    },
  },
};
</script>

<style></style>
