<template>
  <div class="flex items-center relative rounded-2xl overflow-hidden">
    <input
      autocomplete
      :class="[
        error
          ? 'focus:border-error border-error'
          : 'focus:border-primary border-dark-200',
        inputStyle,
      ]"
      class="bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border border-r-0 p-4 md:block appearance-none rounded-tl-2xl rounded-bl-2xl"
      v-model="input"
      v-bind="$attrs"
      @keyup="searchFN"
      @keypress.enter="searchFN"
      @input="(e) => $emit('update:modelValue', e.target.value)"
      placeholder="Search by date name or description"
    />
    <!-- :value="modelValue" -->

    <button
      @click="searchFN"
      style="border: 3px solid transparent"
      class="px-6 sm:px-8 py-4 bg-mint focus:bg-mint focus:outline-none"
      :class="
        showInput
          ? 'rounded-tr-2xl rounded-br-2xl'
          : 'md:rounded-r-2xl md:rounded-none rounded-2xl'
      "
    >
      <img src="../../assets/icons/search.svg?url" alt="" />
    </button>

    <div
      style="max-height: 500px"
      v-if="input.trim()"
      class="overflow-y-auto w-full h-auto bg-white shadow-md z-2 mt-1 rounded-md absolute top-14 left-0 overflow-hidden"
    >
      <easiLoader v-if="loading" />

      <div
        v-for="(item, i) in suggestedBonus"
        :key="i"
        @click="selectSuggestion(item)"
        class="w-full last:border-b-0 border-b border-dark-50 p-2 sm:p-4 cursor-pointer hover:bg-background-50 flex gap-3 items-center"
      >
        <div
          class="text-dark-800 text-md font-medium flex flex-col items-start capitalize"
        >
          <span>{{ item.name }}</span>
          <span class="text-sm text-dark-700 font-thin">{{
            formatAmount(item.amount)
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { ref, computed, watch } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";

const store = useDataStore();
const { query } = store;
const { suggestedBonus, checkLoading } = storeToRefs(store);

const { formatAmount } = helperFunctions;

const emit = defineEmits(["search", "update:modelValue", "show"]);

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "",
  },

  error: {
    type: [String, Boolean],
    default: null,
  },

  type: {
    type: String,
    default: "Bonus",
  },
  shrink: {
    type: Boolean,
    default: false,
  },
});
const watchShrink = computed(() => props.shrink);

watch(watchShrink, (val) => {
  showInput.value = val;
});
const loading = ref(false);
const input = ref("");
const showInput = ref(false);

const inputStyle = computed(() => {
  let style;
  if (showInput.value) {
    style = "block";
  } else {
    style = "hidden md:block";
  }
  return style;
});

const selectSuggestion = async (arg) => {
  input.value = arg ? arg.name : null;
  await searchFN();

  input.value = "";
};

const searchFN = (e) => {
  if (!input.value.length && e.key !== "Backspace") {
    showInput.value = !showInput.value;
    emit("show", showInput.value);
  }

  emit("search", input.value);
};

const querySuggestedBonus = async () => {
  if (props.modelValue.trim() !== "") {
    const payload = {
      paging: {
        limit: 1000,
        skip: 0,
        search: props.modelValue,
      },
      sort: {},
      filter: [],
      type: props.type,
    };

    try {
      loading.value = true;
      await query({
        endpoint: "ListBonusAndDeduction",
        payload: { input: payload },
        service: "PAYROLL",
        storeKey: "suggestedBonus",
      })
        .then(() => {
          console.log(suggestedBonus.value);
          loading.value = false;
        })
        .catch((err) => {
          loading.value = false;
        });
    } catch (err) {
      loading.value = false;
      console.log(err);
    }
  } else {
    store.$patch({
      suggestedBonus: [],
    });
  }
};
</script>

<style></style>
