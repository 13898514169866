import { createApp, defineAsyncComponent } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { registerComponents } from "./utils/components.js";

import { API, upgradeTier } from "./api/api";
import { createApolloProvider } from "@vue/apollo-option";
import Toast from "vue-toastification";
// Import the CSS or use your own!
// import VueExcelEditor from "vue3-excel-editor";
import { Vue3ProgressPlugin } from "@marcoschulte/vue3-progress";
import VueGtag from "vue-gtag";
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";

// import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";
// import VueEasyLightbox from "vue-easy-lightbox";
// import Ripple from "primevue/ripple";
// import Tooltip from "primevue/tooltip";

// // import DataTable from "primevue/datatable";
// import InputText from "primevue/inputtext";
// import Select from "primevue/select";
// import Column from "primevue/column";

import "primeicons/primeicons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/styles/tailwind.css";
import "vue-toastification/dist/index.css";
import "./styles/app.css"; // Here

import firebaseMessaging from "./utils/firebase.js";

// const ProgressBar = defineAsyncComponent(() => import("primevue/progressbar"));
// const Tag = defineAsyncComponent(() => import("primevue/tag"));
// const Drawer = defineAsyncComponent(() => import("primevue/drawer"));
// const Carousel = defineAsyncComponent(() => import("primevue/carousel"));

const app = createApp(App);

app.config.globalProperties.$messaging = firebaseMessaging;

const apolloProvider = createApolloProvider({
  defaultClient: API,
});
app.config.productionTip = false;

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(router);
app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: false || "none",
    },
  },
  ripple: true,
});
app.use(Toast);
app.use(pinia);
app.use(apolloProvider);
app.use(
  VueGtag,
  {
    appName: "myeazipay staging",
    pageTrackerScreenviewEnabled: true,
    config: { id: "G-FV9HDMCJ2H" },
  },
  router
);

app.use(Vue3ProgressPlugin);

// app.use(VueEasyLightbox);
// app.use(Vue3SimpleHtml2pdf);
// app.use(PrimeVue, { unstyled: false });

// app.use(VueExcelEditor);
// app.directive("tooltip", Tooltip);
// app.directive("ripple", Ripple);

// app.component("DataTable", DataTable);
// app.component(
//   "DataTable",
//   defineAsyncComponent(() => import("primevue/datatable"))
// );
// app.component("Checkbox", Checkbox);
// app.component("Dropdown", Select);
// app.component("InputText", InputText);
// app.component("ProgressBar", ProgressBar);
// app.component("Sidebar", Drawer);
// app.component("SideBar", Drawer);
// app.component("Tag", Tag);
// app.component("Column", Column);
// app.component("Avatar", Avatar);
// app.component("Carousel", Carousel);

registerComponents(app);

app.mount("#app");

// function initDojah(element, widget_id) {
//   document.addEventListener("click", function (e) {
//     // console.log(e.target.matches(element));
//     // if (!e.target.matches(element)) return;
//     const options = {
//       app_id: "663123a579a6430040b3fe2d", //your app_id here
//       p_key: "prod_pk_TxJNmL2IHTcv5DjxvmUITp88r", //your production public key here
//       type: "custom",
//       // user_data: {
//       //   first_name: {$first_name}, //optional
//       //   last_name: {$last_name}, //optional
//       //   dob: {$dob}, //YYYY-MM-DD Optional
//       //   residence_country: 'NG', //optional
//       //   email: {$email}//optional
//       // },

//       // metadata: {
//       //   user_id: '12xxxxsxsxsxssx1',
//       // },

//       config: {
//         widget_id, //this is generated from easyonboard
//       },
//       onSuccess: function (response) {
//         console.log("Success", response);
//         if (response.status) {
//           upgradeTier();
//         }
//       },
//       onError: function (err) {
//         console.log("Error", err);
//       },
//       onClose: function () {
//         console.log("Widget closed");
//       },
//     };
//     // console.log(options);
//     const connect = new Connect(options);
//     const button = document.querySelector(element);
//     if (!button) return;
//     button.addEventListener("click", function () {
//       connect.setup();
//       connect.open();
//     });
//   });
// }

// function initAccordion(elem, option) {
//   document.addEventListener("click", function (e) {
//     // Daca elementul nu este .acc-title, nu fa nimic
//     if (!e.target.matches(elem + " .acc-title")) return;
//     else {
//       // Daca parintele (.acc-container) lui .acc-title are clasa .acc-active
//       if (!e.target.parentElement.classList.contains("acc-active")) {
//         // Daca option==true, sterge clasa .acc-active pentru celelalte elemente
//         if (option == true) {
//           var elementList = document.querySelectorAll(elem + " .acc-container");
//           Array.prototype.forEach.call(elementList, function (e) {
//             e.classList.remove("acc-active");
//           });
//         }
//         // Daca nu are clasa .acc-active, adauga clasa .acc-active
//         e.target.parentElement.classList.add("acc-active");
//       } else {
//         // Daca are clasa .acc-active, remove clasa .acc-active
//         e.target.parentElement.classList.remove("acc-active");
//       }
//     }
//   });
// }
// initDojah(".dojah-button", "663141ac79a6430040b413a3");
// initDojah(".dojah-button1", "6631423caace7a0040a15d54");
// initDojah(".dojah-button2", "6631427caace7a0040a15d8c");
// initDojah(".dojah-button3", "663142d7ee631f004030da11");
// Deschide doar unul, inchide celelalte
// initAccordion(".accordion.acc-single-open", true);
// // Deschide multiple, fara a inchide celelalte
// initAccordion(".accordion.acc-multiple-open", false);
