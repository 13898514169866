<template>
  <div>
    <form
      @submit.prevent="submit('submit')"
      class="rounded-2xl md:rounded-3xl border border-dark-100 p-2 md:p-4 flex flex-col md:gap-x-4 text-xs md:text-sm"
    >
      <div class="text-dark-500 text-sm md:text-base -mb-3">
        Basic Information
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-x-4">
        <easiTextInput
          placeholder="First Name"
          v-model="admin.firstName"
          readonly
          required
        ></easiTextInput>
        <easiTextInput
          placeholder="Last Name"
          v-model="admin.lastName"
          required
          readonly
        ></easiTextInput>
        <easiTextInput
          placeholder="Phone Number"
          v-model="admin.phoneNumber"
          readonly
        ></easiTextInput>
        <easiTextInput
          placeholder="Email"
          v-model="admin.email"
          readonly
        ></easiTextInput>
      </div>

      <div class="text-dark-500 text-sm md:text-base mt-6 -mb-3">
        Address Information
      </div>

      <easiTextInput
        placeholder="Residential Address"
        v-model="args.addressInformation.address"
        required
      ></easiTextInput>

      <div
        class="grid grid-cols-1 md:grid-cols-2 items-center gap-4 mt-4 md:mt-5"
      >
        <easiSelectInput2
          :options="countryOption"
          :value="args.addressInformation.country"
          @update="updateCountry($event, 'addressInformation', 'country')"
          placeholder="Country"
        ></easiSelectInput2>
        <easiSelectInput2
          :options="stateOption"
          :value="args.addressInformation.state"
          @update="updateState($event, 'addressInformation', 'state')"
          placeholder="State"
        ></easiSelectInput2>
      </div>

      <div
        class="py-2 px-4 mt-5 border border-dark-100 rounded-xl bg-newGrey flex items-center justify-between"
      >
        <div class="flex items-center gap-2">
          <div
            class="h-12 w-12 flex items-center justify-center bg-white rounded-full flex-shrink-0"
          >
            <img :src="imgIcon" alt="" />
          </div>
          <div class="flex flex-col leading-4">
            Upload proof of residential address e.g. IKEDC, Lawma, etc. (.png,
            jpg)
          </div>
        </div>

        <span class="bg-white rounded-full">
          <easiUpload
            :label="'Upload'"
            :loop="true"
            @fileUrl="uploadFile = $event"
            :imageLink="
              kycInfo &&
              kycInfo.addressInformation &&
              kycInfo.addressInformation.proofOfAddress
            "
          />
        </span>
      </div>

      <div
        class="text-dark-500 text-sm md:text-base md:col-span-2 mt-6 mb-2 md:-mb-2"
      >
        Birth Information
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-4">
        <easiSelectInput2
          :options="[
            {
              label: 'Male',
              value: 'MALE',
            },
            {
              label: 'Female',
              value: 'FEMALE',
            },
            {
              label: 'Other',
              value: 'OTHER',
            },
          ]"
          :value="args.birthInformation.gender"
          @update="args.birthInformation.gender = $event"
          placeholder="Gender"
          class="-mb-3"
        ></easiSelectInput2>
        <easiTextInput
          placeholder="Date of birth"
          v-model="args.birthInformation.dob"
          required
          type="date"
        ></easiTextInput>
      </div>

      <div
        class="grid grid-cols-1 md:grid-cols-2 items-center gap-4 mt-4 md:mt-5"
      >
        <easiSelectInput2
          :options="countryOption"
          :value="args.birthInformation.countryOfOrigin"
          @update="updateCountry($event, 'birthInformation', 'countryOfOrigin')"
          placeholder="Country"
          class="-mb-2"
        ></easiSelectInput2>
        <easiSelectInput2
          :options="stateOption"
          :value="args.birthInformation.stateOfOrigin"
          @update="updateState($event, 'birthInformation', 'stateOfOrigin')"
          placeholder="State"
          class="mt-2"
        ></easiSelectInput2>
        <easiSelectInput2
          :options="lgOption"
          :value="args.birthInformation.lga"
          @update="args.birthInformation.lga = $event"
          placeholder="Local Govermnment"
          class="mt-3"
        ></easiSelectInput2>
        <div class="mt-2 md:mt-0">
          <easiUpload
            label="Upload passport photo"
            @fileUrl="passportFile = $event"
            :imageLink="
              kycInfo &&
              kycInfo.birthInformation &&
              kycInfo.birthInformation.passportPhoto
            "
            dotted
          />
        </div>
      </div>

      <div
        class="flex w-full mt-6 flex-col-reverse md:flex-row md:items-center gap-4 justify-between"
      >
        <easiButton
          type="button"
          @click="() => $emit('saved')"
          :block="breakPoints('md')"
          variant="text"
          color="secondary"
          size="medium"
          >{{ breakPoints("md") ? "Discard Changes" : "Cancel" }}</easiButton
        >

        <div class="flex flex-col md:flex-row md:items-center md:gap-4">
          <easiButton
            @click="submit('save')"
            type="button"
            variant="outlined"
            size="medium"
            class="hidden md:flex"
            >Save Update</easiButton
          >
          <easiButton type="submit" :block="breakPoints('md')" size="medium"
            >Submit</easiButton
          >
        </div>
      </div>
    </form>
    <easiRoundLoader v-if="loading" />
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import { useToast } from "vue-toastification";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";

import imgIcon from "@/assets/icons/img-icon.svg?url";
import countryData from "@/utils/countries.json";
import lgData from "@/utils/localGovt.js";

const uploadFile = ref(null);

const { uploadFileToServer, breakPoints } = helperFunctions;
const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();
const emit = defineEmits(["saved"]);
const toUpdate = ref(false);
const kycInfo = computed(
  () =>
    store.getKyc && store.getKyc.data && store.getKyc.data.personalInformation
);

const args = reactive({
  birthInformation: {
    stateOfOrigin: null,
    passportPhoto: null,
    lga: null,
    gender: null,
    dob: null,
    countryOfOrigin: null,
  },
  addressInformation: {
    state: null,
    proofOfAddress: null,
    country: null,
    address: null,
  },
});
const companyAdmin = computed(() => store.getCompanyAdmin);
const admin = ref({
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
});
const countryOption = computed(() => {
  const data = countryData.map((count) => {
    return {
      label: count.name,
      value: count.name,
    };
  });
  return data;
});
const stateOption = ref([]);

function updateCountry(country, key, subKey) {
  args[key][subKey] = country;
  console.log(args);
  const data = countryData.find(
    (c) => c.name.toLowerCase() == country.toLowerCase()
  );
  stateOption.value = data.states.map((sta) => {
    return {
      label: sta.name,
      value: sta.name.replace(/\s*State$/, ""),
    };
  });
}
const lgOption = ref([]);
function updateState(state, key, subKey) {
  args[key][subKey] = state;
  if (key === "birthInformation") {
    console.log(state, "262");
    const data = lgData.find(
      (c) =>
        c.state.toLowerCase() == state.toLowerCase() ||
        c.state.toLowerCase == state.concat(" State").toLowerCase()
    );
    lgOption.value =
      data &&
      data.lgas.map((lg) => {
        return {
          label: lg,
          value: lg,
        };
      });
  }
}
const loading = ref(false);

const errorRules = reactive({
  firstName: false,
  lastName: false,
  phone: false,
  email: false,
  address: false,
  state: false,
  country: false,
  gender: false,
  dob: false,
  lga: false,
  passport: false,
});
const passportFile = ref(null);

function validate() {
  if ((uploadFile.value && passportFile.value) || toUpdate.value) {
    return true;
  }
  toast.error("Upload all files");
  return false;
}
const queryAdmin = async () => {
  try {
    await query({
      endpoint: "FetchCompanyAdmin",
      service: "SETTINGS",
      storeKey: "companyAdmin",
    });
  } catch (e) {
    console.log(e.message);
  }
};
const createPersonalInformation = async (task) => {
  loading.value = true;

  try {
    if (uploadFile.value) {
      args.addressInformation.proofOfAddress = await uploadFileToServer(
        uploadFile.value
      );
    }
    if (passportFile.value) {
      args.birthInformation.passportPhoto = await uploadFileToServer(
        passportFile.value
      );
    }
    if (
      typeof args.addressInformation.proofOfAddress !== "string" ||
      typeof args.birthInformation.passportPhoto !== "string"
    ) {
      toast.error("Upload failed");
      return;
    }
    let res = await mutate({
      endpoint: "CreatePersonalInformation",
      data: { input: args },
      service: "SETTINGS",
    });

    if (res && res.success) {
      if (task) {
        toast.success(res.message);
        emit("saved", true);
      }
    } else if (res && !res.success) {
      toast.error(res.message);
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
};

async function submit(task) {
  if (validate()) {
    await createPersonalInformation(task);
  }
}
if (
  kycInfo.value &&
  kycInfo.value.addressInformation &&
  kycInfo.value.birthInformation
) {
  toUpdate.value = true;
  args.addressInformation.address = kycInfo.value.addressInformation.address;
  args.addressInformation.proofOfAddress =
    kycInfo.value.addressInformation.proofOfAddress;
  args.birthInformation.passportPhoto =
    kycInfo.value.birthInformation.passportPhoto;
  args.birthInformation.gender = kycInfo.value.birthInformation.gender;
  args.birthInformation.dob = kycInfo.value.birthInformation.dob;
  args.birthInformation.lga = kycInfo.value.birthInformation.lga;
  updateCountry(
    kycInfo.value.addressInformation.country,
    "addressInformation",
    "country"
  );
  updateState(
    kycInfo.value.addressInformation.state,
    "addressInformation",
    "state"
  );

  updateCountry(
    kycInfo.value.birthInformation.countryOfOrigin,
    "birthInformation",
    "countryOfOrigin"
  );
  updateState(
    kycInfo.value.birthInformation.stateOfOrigin,
    "birthInformation",
    "stateOfOrigin"
  );
}
onMounted(async () => {
  await queryAdmin();
  if (companyAdmin.value && companyAdmin.value.companyAdmin) {
    admin.value.firstName = companyAdmin.value.companyAdmin.firstName;
    admin.value.lastName = companyAdmin.value.companyAdmin.lastName;
    admin.value.email = companyAdmin.value.companyAdmin.email;
    admin.value.phoneNumber = companyAdmin.value.companyAdmin.phoneNumber;
  }
});
</script>

<style></style>
